import {Main} from "../components/Main";
import {HowItWorks} from "../components/howItWorks/HowItWorks";
import {Benefits} from "../components/benefits/Benefits";
import {GetTheApp} from "../components/GetTheApp";
import {Features} from "../components/features/Features";
import {Roadmap} from "../components/roadmap/Roadmap";
import {Subscribe} from "../components/subscribe/Subscribe";
import {Team} from "../components/team/Team";
import {Contacts} from "../components/contacts/Contacts";

function MainPage() {
    return <>
        <Main/>
        <HowItWorks/>
        <Benefits/>
        <GetTheApp/>
        <Features/>
        <Roadmap/>
        <Subscribe/>
        <Team/>
        <Contacts/>
    </>
}

export {MainPage}