import {Link, styled} from "@mui/material";

const InnerLink = styled(Link)({
    color: "black",
    textDecorationColor: "black",
    textDecorationThickness: "1px",
    "&:hover": {
        textDecoration: "none"
    }
})

export {InnerLink};