import {forwardRef} from "react";
import {TabContent} from "./TabContent";
import shareImg from "./img/share.png";
import {Typography} from "@mui/material";
import {InnerLink} from "../InnerLink";

const ShareTabContent = forwardRef((props, ref) => {
    return <TabContent image={shareImg} ref={ref}>
        <Typography variant="body2" sx={{marginBottom: "24px"}}>You can share it instantly via email, messengers, social
            networks or cloud
            services.</Typography>
        <Typography variant="body2">The measurement data can also be custom-integrated with your current ERP
            system - please <InnerLink sx={{fontWeight: "bold"}} href="/#contacts">contact us</InnerLink> for more
            information.</Typography>
    </TabContent>
});

export {ShareTabContent};