import {Box, Slide, Typography, useTheme} from "@mui/material";
import {Header2} from "../Header2";
import {Header3} from "../Header3";
import "./styles.scss";
import {Tabs} from "./Tabs";
import React, {useRef, useState} from "react";
// Import Swiper React components
import {Swiper, SwiperSlide} from 'swiper/react';
import {Pagination} from 'swiper/modules';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import {SelectTabContent} from "./SelectTabContent";
import {CaptureTabContent} from "./CaptureTabContent";
import {GetReportContent} from "./GetReportContent";
import {ShareTabContent} from "./ShareTabContent";

import {SelectSlide} from "./SelectSlide";
import {CaptureSlide} from "./CaptureSlide";
import {GetReportSlide} from "./GetReportSlide";
import {ShareSlide} from "./ShareSlide";


function HowItWorks() {
    const theme = useTheme();
    const [selectedIndex, setSelectedIndex] = useState(0);
    const [directions, setDirections] = useState(["left", "left", "left", "left"]);
    const onTabChanged = () => (index) => {
        if (index < selectedIndex) {
            for (let i = 0; i < 4; i++) {
                directions[i] = i <= index ? "right" : "left";
            }
        } else {
            for (let i = 0; i < 4; i++) {
                directions[i] = i < index ? "right" : "left";
            }
        }
        setDirections(directions);
        setSelectedIndex(index);
    }
    const sliderRef = useRef(null);
    const [isTransitionInProgress, setIsTransitionInProgress] = useState({index: 0, inProgress: false});
    const onTransitionExit = (index) => () => setIsTransitionInProgress({index: index, inProgress: false});
    const onTransitionEnter = (index) => () => setIsTransitionInProgress({index: index, inProgress: true});
    const getInStatus = (index) => index === selectedIndex && (!isTransitionInProgress.inProgress || isTransitionInProgress.index === index);

    return <Box sx={{paddingTop: "100px"}}>
        <Header2 id="howItWorks">How it works</Header2>
        <Header3 sx={{
            marginBottom: "59px"
        }}>
            Calculate the <Typography variant="h3b">number</Typography> of logs,<br/>
            their <Typography variant="h3b">diameter</Typography> and total <Typography
            variant="h3b">volume</Typography>,<br/>
            and share the data <Typography variant="h3i">instantly</Typography>
        </Header3>
        <svg width={0} height={0} style={{position: "absolute"}}>
            <linearGradient id="greenGradientImage">
                <stop offset="0%" stopColor={theme.palette.gradients.greenStart}/>
                <stop offset="100%" stopColor={theme.palette.gradients.greenMiddle}/>
            </linearGradient>
            <linearGradient id="greenGradientVertical" x1={0} x2={0} y1={0} y2={1}>
                <stop offset="0%" stopColor={theme.palette.gradients.greenStart}/>
                <stop offset="100%" stopColor={theme.palette.gradients.greenMiddle}/>
            </linearGradient>
            <linearGradient id="orangeGradientImage">
                <stop offset="0%" stopColor={theme.palette.gradients.orange}/>
                <stop offset="100%" stopColor={theme.palette.gradients.orangeGreen}/>
            </linearGradient>
        </svg>
        <Box as="div" sx={{
            borderRadius: "16px",
            border: `1px solid ${theme.palette.borders.inactive}`,
            paddingLeft: "40px",
            paddingRight: "40px",
            paddingTop: "30px",
            paddingBottom: "30px",
            marginLeft: "80px",
            marginRight: "80px",
            display: {
                xs: "none",
                lg: "block"
            },
        }}>
            <Tabs onSelectedIndexChange={onTabChanged()}/>
            <Box sx={{
                border: `1px solid ${theme.palette.borders.inactive}`,
                borderRadius: "16px",
                paddigTop: "14px",
                paddingLeft: "48px",
                backgroundColor: theme.palette.secondary.light,
                height: "335px",
                overflow: "hidden"
            }} ref={sliderRef}>
                <Slide container={sliderRef.current} direction={directions[0]}
                       in={getInStatus(0)} mountOnEnter
                       unmountOnExit onExited={onTransitionExit(0)} onEntered={onTransitionEnter(0)}>
                    <SelectTabContent/>
                </Slide>
                <Slide container={sliderRef.current} direction={directions[1]}
                       in={getInStatus(1)} mountOnEnter
                       unmountOnExit onExited={onTransitionExit(1)} onEntered={onTransitionEnter(1)}>
                    <CaptureTabContent/>
                </Slide>
                <Slide container={sliderRef.current} direction={directions[2]}
                       in={getInStatus(2)} mountOnEnter
                       unmountOnExit onExited={onTransitionExit(2)} onEntered={onTransitionEnter(2)}>
                    <GetReportContent/>
                </Slide>
                <Slide container={sliderRef.current} direction={directions[3]}
                       in={getInStatus(3)} mountOnEnter
                       unmountOnExit onExited={onTransitionExit(3)} onEntered={onTransitionEnter(3)}>
                    <ShareTabContent/>
                </Slide>

            </Box>
        </Box>
        <Box sx={{
            paddingLeft: "20px",
            paddingRight: "20px",
            display: {
                xs: "block",
                lg: "none"
            }
        }}>
            <Swiper spaceBetween={20}
                    pagination={true}
                    modules={[Pagination]} autoHeight={true}>
                <SwiperSlide>
                    <SelectSlide/>
                </SwiperSlide>
                <SwiperSlide>
                    <CaptureSlide/>
                </SwiperSlide>
                <SwiperSlide>
                    <GetReportSlide/>
                </SwiperSlide>
                <SwiperSlide>
                    <ShareSlide/>
                </SwiperSlide>
            </Swiper>
        </Box>


    </Box>
}

export {HowItWorks};