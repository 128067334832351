import {Box, Typography} from "@mui/material";
import bg from "../img/headBg.png";
import {GetTheAppButton} from "./buttons/GetTheAppButton";

function Main() {
    return <Box sx={{
        minHeight: "716px",
        backgroundImage: `url(${bg})`,
        marginLeft: "0px",
        marginRight: "0px",
        paddingLeft: "0px !important",
        paddingRight: "0px !important",
        textAlign: "center",
        paddingTop: "75px"
    }}>
        <Typography variant="h1" id="about" sx={{
            marginBottom: "30px",
            paddingLeft: {
                xs: "8px",
                lg: "240px"
            },
            paddingRight: {
                xs: "8px",
                lg: "240px"
            },
        }}>
            Get AI to measure any log stack within <Typography variant="accent">minutes</Typography>
        </Typography>
        <Typography
            sx={{
                fontSize: "21.4px",
                fontFamily: "Barlow",
                fontWeight: 300,
                marginBottom: "56px",
                paddingLeft: "20px",
                paddingRight: "20px",
            }}>
            with our AI-powered tool for timber industry
        </Typography>
        <Box>
            <GetTheAppButton/>
        </Box>
    </Box>
}

export {Main};