import {Box, Button, CircularProgress, Stack, TextField, Typography, useTheme} from "@mui/material";
import {Header2} from "../Header2";
import bgImage from "./img/contactsBg.png";
import {SumbitContactRequestButton} from "../buttons/SumbitContactRequestButton";
import {InnerLink} from "../InnerLink";
import {useRef, useState} from "react";

function Contacts() {
    const theme = useTheme();
    const firstNameRef = useRef();
    const lastNameRef = useRef();
    const emailRef = useRef();
    const companyNameRef = useRef();
    const companyUrlRef = useRef();
    const messageRef = useRef();

    const [isInProcess, setIsInProcess] = useState();
    const [isSent, setIsSent] = useState(false);


    const handleSubmit = () => async (event) => {
        event.preventDefault();
        const data = {
            firstName: firstNameRef.current.value,
            lastName: lastNameRef.current.value,
            email: emailRef.current.value,
            companyName: companyNameRef.current.value,
            companyUrl: companyUrlRef.current.value,
            message: messageRef.current.value
        }
        try {
            setIsInProcess(true);
            await fetch("/api/RequestForContact", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(data),
            });
            setIsSent(true);
        } finally {
            setIsInProcess(false);
        }
    }

    return <Stack direction="row"
                  justifyContent="space-between"
                  alignItems="stretch"
                  sx={{
                      paddingBottom: {lg: "0px", xs: "40px"}
                  }}
    >
        <Box sx={{width: "100%"}}>
            <Header2 id="contacts" sx={{marginBottom: "0px"}}>Contacts</Header2>
            {(!isInProcess && !isSent) ? <Box component="form" sx={{
                paddingTop: "80px",
                paddingLeft: {lg: "86px", xs: "20px"},
                paddingRight: {lg: "86px", xs: "20px"},
            }} onSubmit={handleSubmit()}>
                <Typography sx={{
                    fontSize: "20px",
                    fontWeight: 600
                }}>Contact us</Typography>
                <Typography variant="body1" sx={{
                    color: theme.palette.text.light,
                    paddingTop: "8px",
                    paddingBottom: "48px",
                }}>Send us a message, and we’ll get back to you within 24 hours.</Typography>

                <Stack direction="column" sx={{rowGap: "10px", paddingBottom: "48px"}}>
                    <Stack
                        justifyContent="space-between"
                        sx={{
                            flexDirection: {lg: "row", xs: "column"},
                            width: "100%",
                            alignItems: {lg: "flex-start", xs: "stretch"},
                            rowGap: "10px",
                        }}
                    >
                        <TextField variant="outlined" label="First name" size="small" inputProps={{maxLenth: 50}}
                                   inputRef={firstNameRef} required/>
                        <TextField variant="outlined" label="Last name" size="small" inputProps={{maxLenth: 50}}
                                   inputRef={lastNameRef} required/>
                    </Stack>
                    <TextField variant="outlined" label="Email" size="small" type="email" inputProps={{maxLenth: 50}}
                               inputRef={emailRef} required/>
                    <TextField variant="outlined" label="Company name" size="small" type="text"
                               inputProps={{maxLenth: 100}} inputRef={companyNameRef}
                               required/>
                    <TextField variant="outlined" label="Company URL" size="small" type="url"
                               inputProps={{maxLenth: 100}} inputRef={companyUrlRef}/>
                    <TextField variant="outlined" label="Message" size="small" type="text"
                               inputProps={{maxLenth: 10000}} inputRef={messageRef} required
                               multiline rows={4}/>
                    <Typography sx={{
                        fontSize: "14px",
                        fontWeight: 400,
                        color: theme.palette.text.light,
                    }}>We'll never share your email with anyone else. To read how we store and protect your
                        data, check out our <InnerLink href="/privacy">Privacy Policy</InnerLink>.</Typography>
                </Stack>
                <Box sx={{
                    textAlign: {lg: "left", xs: "center"}
                }}>
                    <SumbitContactRequestButton/>
                </Box>
            </Box> : <Box sx={{
                display: "block",
                position: "relative",
                top: "50%",
                transform: "translate(0, -50%)",
                textAlign: isSent ? "left" : "center"
            }}>
                {!isSent ? <CircularProgress/> : <Box>
                    <Box sx={{paddingBottom: "40px"}}>
                        <Typography sx={{
                            fontSize: "32px",
                            fontWeight: 400,
                            textTransform: "uppercase",
                            background: "linear-gradient(to right, #F57F16, #009688)",
                            backgroundClip: "text",
                            color: "transparent",
                            display: "inline-block"
                        }}>
                            Thank you!
                        </Typography><br/>
                        <Typography sx={{
                            fontSize: "20px",
                            fontWeight: 400,
                            textTransform: "uppercase",
                            background: "linear-gradient(to right, #F57F16, #009688)",
                            backgroundClip: "text",
                            color: "transparent",
                            display: "inline-block",

                        }}>
                            Your message was successfully sent.
                        </Typography>
                    </Box>
                    <Typography variant="body2">
                        Our team will contact you within 24 hours.
                    </Typography>
                </Box>}
            </Box>
            }
        </Box>
        <Box sx={{
            paddingTop: "13.5px",
            paddingBottom: "0px",
            display: {lg: "flex", xs: "none"},
        }}>
            <img src={bgImage} alt=""/>
        </Box>
    </Stack>
}


export {Contacts};