import {Box, Button, Stack, useTheme} from "@mui/material";
import whiteArrow from "./img/rightArrowWhite.svg";


function SumbitContactRequestButton() {
    const theme = useTheme();
    return <Button type="submit" className="submitButton" sx={{
        background: `linear-gradient(to bottom right, ${theme.palette.gradients.greenStart}, ${theme.palette.gradients.greenEnd})`,
        border: "1px solid transparent",
        color: "white",
        textTransform: "none",
        height: "52px",
        paddingLeft: "65px",
        paddingRight: "65px",
        fontSize: "14px",
        fontWeight: 400,
        "&:hover": {
            background: `linear-gradient(to right, ${theme.palette.gradients.orange}, ${theme.palette.gradients.orangeGreen})`,
            border: "1px solid black",
            color: "black"
        },
    }}>
        <Stack direction="row"
               justifyContent="center"
               alignItems="center"
               sx={{
                   columnGap: "12px"
               }}
        >
            Send
            <Box className="submitButtonImage" sx={{
                width: "13px",
                height: "10px",
                background: `url(${whiteArrow})`,
            }}>
            </Box>
        </Stack>

    </Button>
}

export {SumbitContactRequestButton};