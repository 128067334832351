import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Stack,
    styled,
    useTheme
} from "@mui/material";
import roadmapImg from "./img/roadmap.png";
import {Header2} from "../Header2";
import {useState} from "react";
import {Summary} from "./Summary";

const TOPIC_ADMIN_PANEL = 1;
const TOPIC_API = 2;
const TOPIC_CUSTOM_FIELDS = 3;
const TOPIC_QR = 4;
const TOPIC_IPHONE = 5;


const StyledAccordion = styled((props) =>
    <Accordion disableGutters elevation={0} square {...props} />
)(({theme}) => ({backgroundColor: "transparent"}));


const StyledAccordionSummary = styled((props) =>
    <AccordionSummary {...props}/>
)(({theme}) => ({paddingTop: "16px", paddingBottom: "16px"}));


function Roadmap() {
    const theme = useTheme();
    const [activeTopic, setActiveTopic] = useState();
    const handleChange = (topic) => (event, newExpanded) => {
        setActiveTopic(newExpanded ? topic : false);
    }

    return <Stack direction="row">
        <Box sx={{
            backgroundColor: theme.palette.roadmapBg,
            height: "828px",
            marginTop: "4px",
            paddingTop: "61px",
            width: {lg: "668px", xs: "auto"},

        }}>
            <Header2 id="roadmap" bgColor={theme.palette.roadmapBg}>Plans for the future</Header2>
            <Box sx={{
                paddingLeft: {lg: "80px", xs: "20px"},
                paddingRight: {lg: "80px", xs: "20px"}
            }}>
                <StyledAccordion expanded={activeTopic === TOPIC_ADMIN_PANEL}
                                 onChange={handleChange(TOPIC_ADMIN_PANEL)}>
                    <StyledAccordionSummary>
                        <Summary isActive={activeTopic === TOPIC_ADMIN_PANEL}>Administrative management panel</Summary>
                    </StyledAccordionSummary>
                    <AccordionDetails>The panel will help manage accounts for multiple team members and customize
                        settings based on the specific needs of the business.</AccordionDetails>
                </StyledAccordion>


                <StyledAccordion expanded={activeTopic === TOPIC_API}
                                 onChange={handleChange(TOPIC_API)}>
                    <StyledAccordionSummary>
                        <Summary isActive={activeTopic === TOPIC_API}>
                            Integration API
                        </Summary>
                    </StyledAccordionSummary>
                    <AccordionDetails>This update will connect and synchronize data between the app and the client's ERP
                        system, ensuring fast and secure data management across the organization.</AccordionDetails>
                </StyledAccordion>


                <StyledAccordion expanded={activeTopic === TOPIC_CUSTOM_FIELDS}
                                 onChange={handleChange(TOPIC_CUSTOM_FIELDS)}>
                    <StyledAccordionSummary>
                        <Summary isActive={activeTopic === TOPIC_CUSTOM_FIELDS}>
                            Custom Fields
                        </Summary>
                    </StyledAccordionSummary>
                    <AccordionDetails>Business requirements highly differ depending on the country, industry, or area
                        specifics. This update will allow businesses to create custom fields according to their
                        individual needs.</AccordionDetails>
                </StyledAccordion>


                <StyledAccordion expanded={activeTopic === TOPIC_QR}
                                 onChange={handleChange(TOPIC_QR)}>
                    <StyledAccordionSummary>
                        <Summary isActive={activeTopic === TOPIC_QR}>
                            Log QR Code Tags and Data Binding
                        </Summary>
                    </StyledAccordionSummary>
                    <AccordionDetails>This feature will allow each log to be marked with a unique QR code. Scanning the
                        code will give instant access to log-related data like species, dimensions, assortments or any
                        other previously provided details.Implementing this feature will help to streamline
                        documentation, reduce errors, and boost efficiency in the supply chain.</AccordionDetails>
                </StyledAccordion>

                <StyledAccordion expanded={activeTopic === TOPIC_IPHONE}
                                 onChange={handleChange(TOPIC_IPHONE)}>
                    <StyledAccordionSummary>
                        <Summary isActive={activeTopic === TOPIC_IPHONE}>Apple iPhone Version</Summary>
                    </StyledAccordionSummary>
                    <AccordionDetails>At this point, the app is only available on Android, but we plan to develop an
                        iPhone version as well.</AccordionDetails>
                </StyledAccordion>
            </Box>

        </Box>
        <Box sx={{
            display: {lg: "flex", xs: "none"}
        }}>
            <img src={roadmapImg} alt=""/>
        </Box>
    </Stack>
}

export {Roadmap};