import {Typography,} from "@mui/material";
import {ToggleOn} from "./ToggleOn";
import {BaseSlide} from "./BaseSlide";
import newMeasureImg from "./img/newMeasure.png";

function SelectSlide() {
    return <BaseSlide TitleIcon={ToggleOn} title="Select" caption="Open the app and select
the measurement details" image={newMeasureImg} imgStyle={{maxWidth: "295px"}}>
        <Typography variant="body2" sx={{marginBottom: "12px"}}>Сhoose which method of calculation you would like to use, and enter the log’s length.</Typography>
        <Typography variant="body2">Select the type of tree species and timber grade if needed.</Typography>
    </BaseSlide>
}

export {SelectSlide}