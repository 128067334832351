import {forwardRef} from "react";
import {TabContent} from "./TabContent";
import {Typography} from "@mui/material";
import getReportBig from "./img/getReport.png";

const GetReportContent = forwardRef((props, ref) => {
    return <TabContent image={getReportBig} ref={ref} imgStyle={{maxWidth: "295px"}}>
        <Typography variant="body2">Report includes logs’ diameter, count and volume, the photo of the log stack and the geolocation
            point where the measurement was taken.</Typography>
    </TabContent>
});
export {GetReportContent};