import {Stack, useTheme} from "@mui/material";
import plusIcon from "./img/plus.svg";
import minusIcon from "./img/minus.svg";
import {Header4} from "../Header4";

function Summary({children, isActive}) {
    const theme = useTheme();
    const getHeaderStyle = (isActive) => isActive ? {
        background: `linear-gradient(to bottom right, ${theme.palette.gradients.greenStart}, ${theme.palette.gradients.greenEnd})`,
        backgroundClip: "text",
        color: "transparent",
    } : {};

    return <Stack direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  sx={{width: "100%"}}
    >
        <Header4 isActive={isActive}>{children}</Header4>
        <img src={(isActive ? minusIcon : plusIcon)} alt=""/>
    </Stack>
}

export {Summary};