import {Box, Card, CardActionArea, CardContent, CardMedia, Stack, Typography} from "@mui/material";
import linkedInIcon from "./img/linkedin.svg";
import "./styles.scss";

function Person({name, url, role, children, photo}) {
    const onClick = url => () => window.open(url);

    return <Card sx={{
        maxWidth: {lg: "220px", sm: "220px", xs: "290px"},
        boxShadow: "none"
    }} onClick={onClick(url)}>
        <CardActionArea sx={{
            "& .MuiCardActionArea-focusHighlight": {
                background: "transparent",
            },
        }}>
            <CardMedia
                component="img"
                image={photo}
                height={220}
                alt={name}
            />
            <CardContent sx={{
                paddingLeft: "0px",
                paddingRight: "0px",
            }}>
                <Stack direction="row"
                       justifyContent="flex-start"
                       alignItems="center"
                       sx={{columnGap: "8px"}}
                >
                    <Box as="span" className="linkedInIcon" sx={{
                        display: "inline-block",
                        background: `url(${linkedInIcon}) center center no-repeat`,
                        width: "25px",
                        height: "25px"
                    }}>
                    </Box>
                    <Typography variant="h4" sx={{
                        textTransform: "none",
                        fontSize: "16px",
                        color: "black"
                    }} className="teamMemberName">{name}</Typography>
                </Stack>

                <Typography sx={{
                    fontSize: "14px",
                    fontWeight: 500,
                    paddingTop: "8px",
                    paddingBottom: "16px",
                    color: "black"
                }}>{role}</Typography>
                <Typography variant="body2" sx={{color: "#606060"}}>
                    {children}
                </Typography>
            </CardContent>
        </CardActionArea>
    </Card>
}

export {Person};