import image from "./img/sort.png";
import {Typography, useTheme} from "@mui/material";
import {BenefitSlide, bodyTextStyle, getBodyAccentTextStyle} from "./BenefitSlide";

function SortSlide() {
    const theme = useTheme();
    return <BenefitSlide image={image} title="Sort logs by diameter with one tap"
                         altText="Marking logs by hand is time-consuming, and requires additional team members and equipment.">
        <Typography sx={bodyTextStyle}>You can easily find the exact diameter you need—just input the number, and the
            app will <Typography as="span"
                                 sx={getBodyAccentTextStyle(theme)}>highlight</Typography> the required logs within the
            stack.</Typography>

    </BenefitSlide>
}

export {SortSlide};