import {Box, Grid, Typography, useTheme} from "@mui/material";
import {Header2} from "../Header2";
import {Header3} from "../Header3";
import {BenefitsText} from "./BenefitsText";
import {BenefitsImage} from "./BenefintsImage";
import saveTimeImg from "./img/saveTime.png";
import getData from "./img/getData.png";
import safityImg from "./img/safity.png";
import sortImg from "./img/sort.png";
import {Swiper, SwiperSlide} from "swiper/react";
import {Pagination} from 'swiper/modules';
// Import Swiper styles
import {SaveTimeSlide} from "./SaveTimeSlide";
import {AccurateData} from "./AccurateData";
import {SafitySlide} from "./SafitySlide";
import {SortSlide} from "./SortSlide";


function Benefits() {
    const theme = useTheme();
    return <Box sx={{paddingTop: "100px"}}>
        <Header2 id="benefits">Benefits</Header2>
        <Header3 sx={{paddingBottom: "100px"}}>
            You don’t need to buy any special equipment —<br/>
            <Typography variant="h3i">just</Typography> your mobile phone and our app will do the job
        </Header3>
        <Box sx={{
            paddingLeft: "120px",
            paddingRight: "120px",
            display: {
                xs: "none",
                lg: "block"
            }
        }}>
            <Grid container sx={{
                border: `1px solid ${theme.palette.borders.inactive}`,
                borderRadius: "16px",
                paddigTop: "14px",

                backgroundColor: theme.palette.secondary.light,
            }}>
                <BenefitsText caption="Save time">
                    With the app, you simply snap a photo of the entire stack and complete all measurements and the
                    report in just <Typography variant="body2Accent">under five minutes</Typography>.
                </BenefitsText>
                <BenefitsImage image={saveTimeImg} sx={{borderTopRightRadius: "16px"}}>
                    Manually measuring and compiling a report can take hours.
                </BenefitsImage>

                <BenefitsImage image={getData}>
                    Entering data into reports manually also takes up a significant amount of time and often results in
                    errors.
                </BenefitsImage>
                <BenefitsText caption={<>Get data that is always accurate</>}>
                    Machine learning and image recognition algorithms minimize human error and <Typography
                    variant="body2Accent">automate</Typography> both
                    measurements and data entry.
                </BenefitsText>


                <BenefitsText caption={<>Create a safer workplace</>}>
                    LogSizerPro makes logging safer by <Typography variant="body2Accent">reducing physical
                    interaction</Typography> with log stacks and the amount of time spent in the heat, rain, or snow.
                </BenefitsText>
                <BenefitsImage image={safityImg}>
                    Measuring logs traditionally often needs to be done in harsh weather conditions, which can result in
                    accidents.
                </BenefitsImage>

                <BenefitsImage image={sortImg} sx={{borderBottomLeftRadius: "16px"}}>
                    Marking logs by hand is time-consuming, and requires additional team members and equipment.
                </BenefitsImage>

                <BenefitsText caption={<>Sort logs by diameter with one tap</>}>
                    You can easily find the exact diameter you need - just input the number, and the app will <Typography
                    variant="body2Accent">highlight</Typography> the required logs within the stack.
                </BenefitsText>

            </Grid>
        </Box>
        <Box sx={{
            display: {
                xs: "block",
                lg: "none"
            },
            paddingLeft: "20px",
            paddingRight: "20px",
        }}>
            <Swiper spaceBetween={20}
                    pagination={true}
                    modules={[Pagination]} autoHeight={true}>
                <SwiperSlide>
                    <SaveTimeSlide/>
                </SwiperSlide>
                <SwiperSlide>
                    <AccurateData/>
                </SwiperSlide>
                <SwiperSlide>
                    <SafitySlide/>
                </SwiperSlide>
                <SwiperSlide>
                    <SortSlide/>
                </SwiperSlide>
            </Swiper>
        </Box>
    </Box>
}

export {Benefits}