import {SvgIcon} from "@mui/material";

function Camera({sx, ...props}) {
    return <SvgIcon sx={sx} {...props}>
        <path
            d="M12.0008 16.4998C12.9841 16.4998 13.8133 16.1623 14.4883 15.4873C15.1633 14.8123 15.5008 13.9831 15.5008 12.9998C15.5008 12.0165 15.1633 11.1873 14.4883 10.5123C13.8133 9.8373 12.9841 9.4998 12.0008 9.4998C11.0174 9.4998 10.1883 9.8373 9.51328 10.5123C8.83828 11.1873 8.50078 12.0165 8.50078 12.9998C8.50078 13.9831 8.83828 14.8123 9.51328 15.4873C10.1883 16.1623 11.0174 16.4998 12.0008 16.4998ZM12.0008 15.7998C11.2008 15.7998 10.5341 15.5331 10.0008 14.9998C9.46745 14.4665 9.20078 13.7998 9.20078 12.9998C9.20078 12.1998 9.46745 11.5331 10.0008 10.9998C10.5341 10.4665 11.2008 10.1998 12.0008 10.1998C12.8008 10.1998 13.4674 10.4665 14.0008 10.9998C14.5341 11.5331 14.8008 12.1998 14.8008 12.9998C14.8008 13.7998 14.5341 14.4665 14.0008 14.9998C13.4674 15.5331 12.8008 15.7998 12.0008 15.7998ZM4.80078 19.6998C4.36745 19.6998 4.00911 19.5581 3.72578 19.2748C3.44245 18.9915 3.30078 18.6331 3.30078 18.1998V7.7998C3.30078 7.36647 3.44245 7.00814 3.72578 6.7248C4.00911 6.44147 4.36745 6.2998 4.80078 6.2998H7.70078L9.55078 4.2998H14.4508L16.3008 6.2998H19.2008C19.6341 6.2998 19.9924 6.44147 20.2758 6.7248C20.5591 7.00814 20.7008 7.36647 20.7008 7.7998V18.1998C20.7008 18.6331 20.5591 18.9915 20.2758 19.2748C19.9924 19.5581 19.6341 19.6998 19.2008 19.6998H4.80078ZM4.80078 18.9998H19.2008C19.4341 18.9998 19.6258 18.9248 19.7758 18.7748C19.9258 18.6248 20.0008 18.4331 20.0008 18.1998V7.7998C20.0008 7.56647 19.9258 7.3748 19.7758 7.2248C19.6258 7.0748 19.4341 6.9998 19.2008 6.9998H16.0008L14.1508 4.9998H9.85078L8.00078 6.9998H4.80078C4.56745 6.9998 4.37578 7.0748 4.22578 7.2248C4.07578 7.3748 4.00078 7.56647 4.00078 7.7998V18.1998C4.00078 18.4331 4.07578 18.6248 4.22578 18.7748C4.37578 18.9248 4.56745 18.9998 4.80078 18.9998Z"
        />
    </SvgIcon>
}

export {Camera};