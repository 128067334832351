import {Box, Grid, Typography} from "@mui/material";
import {Header2} from "../Header2";
import {Header3} from "../Header3";
import vk from "./img/kelbas.jpg";
import tb from "./img/bojarski.jpg";
import pb from "./img/pavelb.jpg";
import am from "./img/alla.jpg";

import {Person} from "./Person";

function Team() {
    return <Box sx={{paddingTop: "112px"}}>
        <Header2 id="team">Team</Header2>
        <Header3>
            Together we have more than 100 years of <Typography variant="h3i">work experience</Typography> and work
            from 3 countries
        </Header3>

        <Grid container spacing={2} sx={{
            paddingTop: "56px",
            paddingLeft: {lg:"94px", xs: "20px"},
            paddingRight: {lg:"94px", xs: "20px"},
            paddingBottom: "96px",
            justifyContent: "center",

        }}>
            <Grid item lg={3}>
                <Person
                    name="Taras Boyarski"
                    photo={tb}
                    role="Co-Founder and Domain Expert"
                    url="https://www.linkedin.com/in/taras-boyarski-735759252/"
                >
                    Timber industry veteran with <Typography variant="body2Accent">38 years of experience</Typography>,
                    sawmill owner, and author of 5 guides and reference books on forest management.
                </Person>
            </Grid>


            <Grid item lg={3}>
                <Person
                    name="Vladimir Kelbas"
                    photo={vk}
                    role="Co-Founder and CTO"
                    url="https://www.linkedin.com/in/vladimir-kelbas/"
                >
                    Software engineer with <Typography variant="body2Accent">23 years of experience</Typography> in
                    creating, and supporting complex software for industrial applications.
                </Person>
            </Grid>


            <Grid item lg={3}>
                <Person
                    name="Pavel Boyarski"
                    photo={pb}
                    role="Co-Founder and CEO"
                    url="https://www.linkedin.com/in/paul-b-870738a9/"
                >
                    Marketer and startup founder, skilled in strategic planning for software applications and web based
                    services.
                </Person>
            </Grid>

            <Grid item lg={3}>
                <Person
                    name="Alla McCoy"
                    photo={am}
                    role="Advisor"
                    url="https://www.linkedin.com/in/alla-mccoy-1b08869/"
                >
                    Director of Startup Support at University of Maryland with over <Typography variant="body2Accent">15
                    years of experience</Typography> in entrepreneurship and technology transfer.
                </Person>
            </Grid>
        </Grid>
    </Box>
}

export {Team};