import {CookieConsent} from "react-cookie-consent";
import React from "react";
import {useTheme} from "@mui/material";
import {InnerLink} from "../InnerLink";
import "./styles.scss";

const consentCookieName = "cookie-accept";

function Consent({onCookiesChange}) {
    const theme = useTheme();
    return <CookieConsent
        location="bottom"
        buttonText="Accept"
        cookieName={consentCookieName}
        style={{background: "#F2F9FF", color: "black", padding: "20px"}}
        expires={150}
        onAccept={() => onCookiesChange ? onCookiesChange() : {}}
    >
        We use cookies to enhance your browsing experience and analyze site traffic. By using our site, you agree to the
        use of cookies — you can manage your preferences or withdraw your consent at any time. To learn more, check out
        our <InnerLink href="/privacy">Privacy Policy</InnerLink>.
    </CookieConsent>
}

export {Consent, consentCookieName}