import {Box, Stack, Typography, useTheme} from "@mui/material";

function Feature({icon, header, children}) {
    const theme = useTheme();
    return <Box sx={{
        width: {lg: "400px", xs: "auto"}
    }}>
        <Stack direction="row"
               justifyContent="flex-start"
               alignItems="center" sx={{
            columnGap: "8px"
        }}>
            <img src={icon} alt=""/>
            <Typography variant="h5Feture" component="h5">{header}</Typography>
        </Stack>

        <Typography variant="body2" sx={{
            color: theme.palette.text.light,
            paddingTop: "12px",
        }}>{children}</Typography>
    </Box>
}

export {Feature};