import {Box, Typography} from "@mui/material";
import React from "react";

function Header2({children, sx, id, bgColor = "white"}) {
    return <Typography variant="h2" id={id} sx={{
        background: "linear-gradient(to right, #F57F16, #009688)",
        backgroundSize: "100% 2px",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        paddingLeft: { lg: "87px", xs: "18px"},
        marginBottom: "68px",
        ...sx,

    }}>
        <Box as="span"
             sx={{
                 display: "inline-block",
                 backgroundColor: bgColor,
                 paddingLeft: "6px",
                 paddingRight: "6px"
             }}
        >
            <Box as="span" sx={{
                background: "linear-gradient(to right, #F57F16, #009688)",
                color: "transparent",
                backgroundClip: "text",
            }}>
                {children}
            </Box>
        </Box>
    </Typography>
}

export {Header2}