import {
    Box,
    Button, CircularProgress,
    InputBase,
    Stack,
    styled, TextField,
    Typography,
    useTheme
} from "@mui/material";

import "./styles.scss";
import {useRef, useState} from "react";

function Subscribe() {
    const theme = useTheme();
    const normalBorderColor = '#FBFAF9';

    const [isInProcess, setIsInProcess] = useState(false);
    const [isSubscribed, setIsSubscribed] = useState(false);
    const ref = useRef();
    const subscribe = async (event) => {
        event.preventDefault();
        try {
            setIsInProcess(true);
            await fetch("/api/Subscribe", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({email: ref.current.value}),
            });
            setIsSubscribed(true);
        } finally {
            setIsInProcess(false);
        }
        alert(ref.current.value);
    }

    const StyledTextField = styled(props => <InputBase sx={{
        width: {lg: "495px", xs: "auto"},
    }} {...props}/>)({
        '& .MuiInputBase-input': {
            height: "39px",

            borderRadius: "10px 0px 0px 10px",
            borderLeft: `1px solid ${normalBorderColor}`,
            borderTop: `1px solid ${normalBorderColor}`,
            borderBottom: `1px solid ${normalBorderColor}`,
            borderRight: 'none',
            paddingLeft: "24px",
            paddingRight: "24px",
            color: normalBorderColor,
        },
    });

    return <Box sx={{
        paddingTop: "64px",
        paddingBottom: "76px",
        paddingLeft: {xs: "20px", lg: "0px"},
        paddingRight: {xs: "20px", lg: "0px"},
        background: `linear-gradient(to bottom right, ${theme.palette.gradients.greenStart}, ${theme.palette.gradients.greenMiddle})`,
        textAlign: "center",
        minHeight: "220px",
    }}>
        {(!isInProcess && !isSubscribed) ? <>
            <Typography variant="caption" sx={{color: "white"}}>Subscribe to our newsletter</Typography>
            <Typography variant="body2" sx={{
                color: "white",
                fontWeight: 300,
                paddingTop: "24px",
                paddingBottom: "48px",
                paddingLeft: {lg: "300px", xs: "20px"},
                paddingRight: {lg: "300px", xs: "20px"},
            }}>No spam, we take this seriously. Just occasional emails with
                the key info that can significantly enhance your workflow.</Typography>
            <Stack direction="row"
                   justifyContent="center"
                   alignItems="center"
                   as="form"
                   onSubmit={subscribe}
            >
                <StyledTextField id="subscribeEmailInput" type="email" placeholder="Email" inputRef={ref} required/>
                <Button id="subscribeButton" sx={{
                    border: `1px solid ${normalBorderColor}`,
                    background: `${normalBorderColor}`,
                    height: "50px",
                    borderRadius: "0px 10px 10px 0px",
                    textTransform: "none",
                    paddingLeft: {lg: "73px", xs: "10px"},
                    paddingRight: {lg: "73px", xs: "10px"},
                    "&:hover": {
                        background: `linear-gradient(to right, ${theme.palette.gradients.orange}, ${theme.palette.gradients.orangeGreen})`,
                    },
                }}
                        type="submit"
                >
                    <Typography className="subscribeButtonText" sx={{
                        fontWeight: 600,
                        background: `linear-gradient(to right, ${theme.palette.gradients.greenStart}, ${theme.palette.gradients.greenMiddle})`,
                        backgroundClip: "text",
                        color: "transparent",
                    }}>
                        Subscribe
                    </Typography>

                </Button>
            </Stack>
        </> : <Box sx={{
            minHeight: "220px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center"
        }}>
            {isSubscribed ? <Typography sx={{
                    color: "white",
                    fontSize: "30px",
                    fontWeight: 400
                }}
                >You are all set. Thank you for subscribing! </Typography> :
                <CircularProgress sx={{
                    color: "white"
                }}/>
            }
        </Box>}

    </Box>
}

export {Subscribe};