import {BenefitSlide, bodyTextStyle, getBodyAccentTextStyle} from "./BenefitSlide";
import image from "./img/saveTime.png";
import {Typography, useTheme} from "@mui/material";

function SaveTimeSlide() {
    const theme = useTheme();
    return <BenefitSlide image={image} title="Save Time"
                         altText="Manually measuring and compiling a report can take hours.">
        <Typography sx={bodyTextStyle}>With the app, you simply snap a photo of the entire stack and complete all
            measurements and the report in just <Typography as="span" sx={getBodyAccentTextStyle(theme)}>under five
                minutes</Typography>.</Typography>
    </BenefitSlide>
}

export {SaveTimeSlide};