import {Container, ThemeProvider} from "@mui/material";
import "./App.scss";
import {TopMenu} from "./components/TopMenu";
import {Footer} from "./components/footer/Footer";
import {Outlet, useLocation} from "react-router-dom";
import React, {useEffect, useRef, useState} from "react";
import theme from './theme';
import {getCookieConsentValue} from "react-cookie-consent";
import {Consent, consentCookieName} from "./components/consent/Consent";
import {Analytics} from "./components/Analytics";


function App() {
    const location = useLocation();
    const lastHash = useRef('');
    const [consentValue, setConsentValue] = useState(getCookieConsentValue(consentCookieName));

    useEffect(() => {
        if (location.hash) {
            lastHash.current = location.hash.slice(1);
        }
        if (lastHash.current && document.getElementById(lastHash.current)) {
            setTimeout(() => {
                const {offsetHeight} = document.getElementById("appBar");
                let elementTop = document.getElementById(lastHash.current)?.getBoundingClientRect()?.top ?? -1;
                if (elementTop === -1) {
                    return;
                }
                elementTop -= document.body.getBoundingClientRect().top;
                const top = elementTop - offsetHeight - 10;
                window.scrollTo({top: top, behavior: "smooth"});
                lastHash.current = '';
            }, 100);
        }
    }, [location]);

    return (<ThemeProvider theme={theme}>
            <TopMenu/>
            <Container sx={{maxWidth: "1280px !important", padding: "0px !important"}}>
                <Outlet/>
                <Footer/>
                <Consent onCookiesChange={() => setConsentValue(getCookieConsentValue(consentCookieName))}/>
                <Analytics cookiesAccepted={consentValue}/>
            </Container>
        </ThemeProvider>
    );
}

export default App;
