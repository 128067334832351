import {Grid, Typography} from "@mui/material";

function BenefitsText({caption, children}) {
    return <Grid item lg={6} sx={{
        height: "296px",
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        paddingRight: "80px",
        paddingLeft: "48px",
        color: "black"


    }}>
        <Typography variant="h4" sx={{marginBottom: "24px"}}>{caption}</Typography>
        <Typography variant="body2">{children}</Typography>
    </Grid>
}

export {BenefitsText};