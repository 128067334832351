import {forwardRef} from "react";
import {TabContent} from "./TabContent";
import {Typography} from "@mui/material";
import captureImgBig from "./img/captureBig.png";

const CaptureTabContent = forwardRef((props, ref) => {
    return <TabContent ref={ref} image={captureImgBig} contentWidth={4} rightAlign="center" imgStyle={{maxWidth: "607px"}}>
        <Typography variant="body2" sx={{marginBottom: "24px"}}>Put a gauge in front of the log pile so the camera has a
            reference point, and adjust its size on the
            screen.</Typography>
        <Typography variant="body2">Any stick can be used as a gauge if you know its length.</Typography>
    </TabContent>
})

export {CaptureTabContent};