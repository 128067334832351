import {Box, Grid} from "@mui/material";
import {forwardRef} from "react";

const TabContent = forwardRef((props, ref) => {
    const {children, image, contentWidth = 6, rightAlign = "flex-end", imgStyle={}} = props;

    return <Grid ref={ref} container sx={{height: "100%"}}>
        <Grid item lg={contentWidth}>
            <Box sx={{
                paddingRight: "48px",
                height: "100%",
            }}>
                <Box sx={{
                    display: "inline-block",
                    verticalAlign: "middle",
                    position: "relative",
                    top: "50%",
                    transform: "translate(0, -50%)",
                }}>
                    {children}
                </Box>
            </Box>
        </Grid>
        <Grid item lg={12 - contentWidth} sx={{display: "flex", alignItems: rightAlign}}>
            <img src={image} alt="" style={imgStyle}/>
        </Grid>
    </Grid>
});

export {TabContent};