import {Box, Button, Container, Stack, Typography, useTheme} from "@mui/material";
import React from "react";
import "./buttons.scss";
import rightArrow from "./img/rightArrowFilled.svg";

function GetTheAppWhiteButton({sx}) {
    const theme = useTheme();
    return <Button className="getAppLightButton" href="https://play.google.com/store/apps/details?id=com.fosize.android"
                   sx={{
                       background: `linear-gradient(to bottom right, ${theme.palette.gradients.greenStart}, ${theme.palette.gradients.greenEnd})`,
                       borderRadius: "10px",
                       padding: "1px",
                       height: "50px",
                       '&:hover': {
                           background: `black`,
                       },
                       ...sx
                   }}>
        <Container component="div" className="innerDiv" sx={{
            borderRadius: "9px",
            backgroundColor: "white",
            height: "48px",
            padding: "0px !important",
        }}>
            <Typography sx={{
                paddingLeft: "47px",
                paddingRight: "35px",
                fontSize: "14px",
                textTransform: "none",
                fontWeight: 400,
                paddingTop: "13px",
                background: `linear-gradient(to bottom right, ${theme.palette.gradients.greenStart}, ${theme.palette.gradients.greenMiddle})`,
                backgroundClip: "text",
                color: "transparent",
            }}>
                <Stack direction="row"
                       justifyContent="center"
                       alignItems="center">
                    Get the app
                    <Box as="span" className="arrowBox" sx={{
                        display: "inline-block",
                        width: "18px",
                        height: "15px",
                        paddingLeft: "5px",
                        background: `url(${rightArrow}) no-repeat right center`,
                    }}>
                    </Box>
                    {/*<img src={rightArrow} alt="" style={{
                        verticalAlign: "middle",
                        color: `red`
                    }}/>*/}
                    {/*<GradientFillIcon Icon={RightArrow} sx={{verticalAlign: "middle", width: "13px", height: "10px"}} showGradient={true}/>*/}
                </Stack>
            </Typography>
        </Container>
    </Button>
}

export {GetTheAppWhiteButton}