import {Grid} from "@mui/material";

function BenefitsImage({image, children, sx}) {
    return <Grid item lg={6} sx={{
        height: "296px",
        background: `url(${image}) no-repeat`,
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        color: "white",
        paddingLeft: "48px",
        paddingRight: "134px",
        ...sx
    }}>
        {children}
    </Grid>
}

export {BenefitsImage};