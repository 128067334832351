import {Box, Stack, styled, Typography, useTheme} from "@mui/material";
import React from "react";
import {FooterBlock} from "./FooterBlock";
import logo from "../../img/logo.svg";
import {DetailItem} from "./DetailItem";
import {useNavigate} from "react-router-dom";
import {Link} from "react-router-dom";

const StyledLink = styled(Link)({
    color: "black",
    fontSize: "14px",
    fontWeight: 300,
    textDecoration: "none",
    cursor: "pointer",
    "&:hover": {
        textDecoration: "underline"
    }
})

function Footer() {
    const theme = useTheme();
    const navigate = useNavigate();
    const goTo = url => () => navigate(url);

    return <Stack direction="row"
                  alignItems="stretch"
                  flexWrap="wrap"
                  sx={{
                      backgroundColor: theme.palette.roadmapBg,
                      paddingTop: "56px",
                      paddingLeft: {lg: "180px", xs: "20px"},
                      paddingRight: {lg: "180px", xs: "20px"},
                      paddingBottom: {lg: "84px", xs: "0px"},
                      justifyContent: {lg: "space-between", xs: "center"}
                  }}>
        <FooterBlock title="Quick links">
            <Stack direction="column"
                   justifyContent="flex-start"
                   alignItems="flex-start"
                   sx={{
                       rowGap: "10px"
                   }}>
                <StyledLink to="/#about">About</StyledLink>
                <StyledLink to="/#howItWorks">How it works</StyledLink>
                <StyledLink to="/#benefits">Benefits</StyledLink>
                <StyledLink to="/#features">Features</StyledLink>
                <StyledLink to="/#roadmap">Plans for the future</StyledLink>
                <StyledLink to="/#team">Team</StyledLink>
            </Stack>
        </FooterBlock>
        <FooterBlock title="Policies" bottom={<img src={logo} alt=""/>}>
            <Stack direction="column"
                   justifyContent="flex-start"
                   alignItems="flex-start"
                   sx={{
                       rowGap: "10px"
                   }}>
                <StyledLink to="/terms">Terms and Conditions</StyledLink>
                <StyledLink to="/privacy">Privacy Policy</StyledLink>
            </Stack>
        </FooterBlock>
        <FooterBlock title="Details">
            <Stack direction="column"
                   justifyContent="flex-start"
                   alignItems="flex-start"
                   sx={{
                       rowGap: "10px"
                   }}>
                <DetailItem name="KRS">5214005291</DetailItem>
                <DetailItem name="REGON">524436743</DetailItem>
                <DetailItem name="NIP">0001018752</DetailItem>
            </Stack>
        </FooterBlock>
        <FooterBlock title="Contacts" bottom={<Typography sx={{
            color: "#5F6368",
            fontSize: "14px",
            width: "100%",
            height: "40px",
        }}>© 2024 Fosize PSA</Typography>}>
            <Stack direction="column"
                   justifyContent="flex-start"
                   alignItems="flex-start"
                   sx={{
                       rowGap: "10px"
                   }}>
                <DetailItem name="Email:">
                    <StyledLink href="mailto:contact@logsizer.com">contact@logsizer.com</StyledLink>
                </DetailItem>
                <DetailItem name="Phone:">+48571522553</DetailItem>
                <DetailItem name="Adress:">ul. Cyberneryki 19B<br/> IIIP., 02-677, Warszawa</DetailItem>
                <Box sx={{
                    display: {lg: "none", xs: "block"},
                    marginTop: "40px",
                    textAlign: "center",
                }}>
                    <img src={logo} alt=""/>
                    <Typography sx={{
                        color: "#5F6368",
                        fontSize: "14px",
                        width: "100%",
                        height: "40px",
                    }}>© 2024 Fosize PSA</Typography>
                </Box>

            </Stack>
        </FooterBlock>
    </Stack>
}

export {Footer};