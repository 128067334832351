import {Box, Typography} from "@mui/material";
import "./styles.scss";
import {TermsSection} from "../components/TermsSection";
import {InnerLink} from "../components/InnerLink";

function PrivacyPolicyPage() {
    return <Box sx={{
        paddingLeft: {lg: "294px", xs: "20px"},
        paddingRight: {lg: "294px", xs: "20px"},
    }}>
        <h1>LogSizerPro Privacy Policy</h1>
        <TermsSection>
            <Typography variant="body3">At LogSizerPro, accessible at <InnerLink
                href="https://logsizer.pro">https://logsizer.pro</InnerLink>, one of
                our main
                priorities is the privacy of our
                visitors. This Privacy Policy document contains types of information that is collected and recorded by
                Logsizer and how we use it.
            </Typography>
            <Typography variant="body3">
                If you have additional questions or require more information about our Privacy Policy, do not hesitate
                to
                contact us.
            </Typography>
        </TermsSection>
        <TermsSection title="Log Files">
            <Typography variant="body3">
                LogSizerPro follows a standard procedure of using log files. These files log visitors when they visit
                websites. All hosting companies do this and it is a part of hosting services' analytics.
                The information collected by log files includes internet protocol (IP) addresses, browser type, Internet
                Service Provider (ISP), date and time stamp, referring/exit pages, and possibly the number of
                clicks. </Typography>
            <Typography variant="body3">
                These are not linked to any information that is personally identifiable. The purpose of the information
                is for analyzing trends, administering the site, tracking users' movement on the website, and gathering
                demographic information (country). </Typography>
        </TermsSection>
        <TermsSection title="Cookies and Web Beacons">
            <Typography>
                Like any other website, LogSizerPro uses "cookies". These cookies are used to store information
                including visitors' preferences, and the pages on the website that the visitor accessed or
                visited. </Typography>
            <Typography>
                The information is used to optimize the users' experience by customizing our web page content based on
                visitors' browser type and/or other information. </Typography>
        </TermsSection>
        <TermsSection title="Third Party Privacy Policies">
            <Typography variant="body3">Third-party ad servers or ad networks use technologies such as cookies,
                JavaScript, or Web
                Beacons in their advertisements and links that appear on LogSizerPro, which are sent directly to users'
                browsers. These technologies automatically receive your IP address when this occurs. They are used to
                measure the effectiveness of advertising campaigns and/or to personalize the advertising content you see
                on websites you visit.</Typography>
            <Typography variant="body3">Please note that LogSizerPro has no access to or control over these cookies used
                by third-party
                advertisers. LogSizerPro's Privacy Policy does not apply to other advertisers or websites. Therefore, we
                advise you to consult the respective Privacy Policies of these third-party ad servers for more detailed
                information on their practices and instructions on how to opt-out of certain options. You can disable
                cookies through your browser settings.</Typography>
            <Typography variant="body3">For more detailed information about cookie management with specific web
                browsers, please visit
                the browsers' respective websites.</Typography>
        </TermsSection>
        <TermsSection title="Data collection methods">
            <Typography variant="body3">LogSizerPro collects personal data when individuals voluntarily fill in forms on
                our website, send questions or messages via our website or online tools as well as automatic data
                contained in system logs and cookies.</Typography>
            <Typography variant="body3">Personal data may include a different range of data, depending on the category
                of data subject and the purpose for which the data are collected.</Typography>
            <Typography variant="body3">Each time LogSizerPro processes only the necessary range of data.</Typography>
            <Typography variant="body3">LogSizerPro processes personal data, given voluntarily through the form on the
                website, such as:
                <ul>
                    <li>identification data (e.g. first name, surname)</li>
                    <li>contact data (e.g. telephone number, e-mail address, address of residence)</li>
                    <li>data on enquiries and contact person data (title)</li>
                </ul>
            </Typography>
            <Typography variant="body3">In connection with the use of our websites, we also collect data in cookies or
                other similar technologies (e.g. tags, pixels).</Typography>
            <Typography variant="body3">Forms for collecting personal data are addressed to adults. Therefore,
                LogSizerPro does not knowingly process personal data of persons under 18 years of age. Consents given by
                individuals are valid for persons over 18 years of age. If users are under 18 years of age, they should
                not provide any information.</Typography>
        </TermsSection>
        <TermsSection title="Links to Other Sites">
            <Typography variant="body3">
                Our website may contain links to other sites. If you click on a third-party link, you will be directed
                to that site. Note that these external sites are not operated by us. Therefore, we strongly advise you
                to review the Privacy Policy of these websites. We have no control over, and assume no responsibility
                for the content, privacy policies, or practices of any third-party sites or services.
            </Typography>
        </TermsSection>
        <TermsSection title="Online Privacy Policy Only">
            <Typography variant="body3">This Privacy Policy applies only to our online activities and is valid for
                visitors to our website with regards to the information that they shared and/or collect in LogSizerPro.
                This policy is not applicable to any information collected offline or via channels other than this
                website.</Typography>
        </TermsSection>
        <TermsSection title="Consent">
            <Typography variant="body3">
                By using our website, you hereby consent to our Privacy Policy.
            </Typography>
        </TermsSection>
    </Box>
}

export {PrivacyPolicyPage}