import {BaseSlide} from "./BaseSlide";
import {Camera} from "./Camera";
import image from "./img/captureSmall.png";
import {Typography} from "@mui/material";

function CaptureSlide() {
    return <BaseSlide TitleIcon={Camera} title="Capture" caption="Take a photo of the log stack with a mobile phone"
                      image={image} leftAlign imgStyle={{maxWidth: "335px"}}
    >
        <Typography variant="body2" sx={{marginBottom: "12px"}}>Put a gauge in front of the log pile so the camera has a
            reference point, and adjust its size on the screen.</Typography>
        <Typography variant="body2">Any stick can be used as a gauge if you know its length.</Typography>
    </BaseSlide>
}

export {CaptureSlide};