import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {CssBaseline} from "@mui/material";
import "./global.scss";
import {createBrowserRouter, createRoutesFromElements, Route, RouterProvider} from "react-router-dom";
import {MainPage} from "./pages/MainPage";
import {TermsPage} from "./pages/TermsPage";
import {PrivacyPolicyPage} from "./pages/PrivacyPolicyPage";
import {AppPrivacy} from "./pages/AppPrivacy";

const root = ReactDOM.createRoot(document.getElementById('root'));

const routeProvider = createBrowserRouter(createRoutesFromElements(<>
    <Route path="/" exact={true} Component={App}>
        <Route index Component={MainPage}/>
        <Route path="terms" Component={TermsPage}/>
        <Route path="privacy" Component={PrivacyPolicyPage}/>
        <Route path="appPrivacyPolicy" Component={AppPrivacy} />
    </Route>
</>));

root.render(
    <React.StrictMode>
        <CssBaseline/>
        <RouterProvider router={routeProvider}/>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
