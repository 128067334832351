import {Typography, useTheme} from "@mui/material";

function Header4({children, isActive}) {
    const theme = useTheme();
    const style = isActive ? {
        background: `linear-gradient(to bottom right, ${theme.palette.gradients.greenStart}, ${theme.palette.gradients.greenMiddle})`,
        backgroundClip: "text",
        color: "transparent",
    } : {};
    return <Typography variant="h4" sx={{...style}}>{children}</Typography>
}

export {Header4}