import {Box, Grid, Stack, Typography, useTheme} from "@mui/material";

function BaseSlide({TitleIcon, title, caption, children, image, leftAlign = false, imgStyle = {}}) {
    const theme = useTheme();
    return <Stack direction="column" sx={{
        borderRadius: "16px",
        border: `1px solid ${theme.palette.borders.inactive}`,
        backgroundColor: theme.palette.secondary.light,
        paddingTop: "36px",
        paddingLeft: "20px",
        paddingRight: "20px",
        height: "640px",
        marginBottom: "40px"
    }}>
        <Stack direction="row" alignItems="center" height={24} spacing={1} sx={{marginBottom: "12px"}}>
            <TitleIcon className="activeTabTitleIcon"/>
            <Box>
                <Typography variant="h5"
                            sx={{
                                background: `linear-gradient(to right, ${theme.palette.gradients.greenStart}, ${theme.palette.gradients.greenMiddle})`,
                                backgroundClip: "text",
                                color: "transparent"
                            }}>
                    {title}
                </Typography>
            </Box>
        </Stack>
        <Typography sx={{
            fontSize: "16px",
            fontWeight: 600,
            color: "black",
        }}>
            {caption}
        </Typography>
        <Box sx={{
            marginLeft: "22px",
            paddingLeft: "2px",
            background: `linear-gradient(to bottom, ${theme.palette.gradients.greenStart}, ${theme.palette.gradients.greenMiddle})`,
            marginTop: "30px",
            marginBottom: "60px",
        }}>
            <Box sx={{
                backgroundColor: theme.palette.secondary.light,
                paddingLeft: "8px"
            }}>
                {children}
            </Box>
        </Box>
        <Grid sx={{
            display: "flex",
            alignItems: "flex-end",
            flexGrow: 1,
            justifyContent: leftAlign ? "flex-start" : "center",
            marginLeft: leftAlign ? "-20px" : "0px",
            marginBottom: leftAlign ? "30px" : "0px",
        }}>
            <img src={image} alt="" style={imgStyle}/>
        </Grid>
    </Stack>
}

export {BaseSlide};