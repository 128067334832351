import {TabContent} from "./TabContent";
import newMeasure from "./img/newMeasure.png";
import {Typography} from "@mui/material";
import {forwardRef} from "react";

const SelectTabContent = forwardRef((props, ref) => {
    return <TabContent ref={ref} image={newMeasure} imgStyle={{maxWidth: "295px"}}>
        <Typography variant="body2" sx={{marginBottom: "24px"}}>
            Сhoose which method of calculation you would like to use, and enter the log’s length.
        </Typography>
        <Typography variant="body2">
            Select the type of tree species and timber grade if needed.
        </Typography>
    </TabContent>
});


export {SelectTabContent};