import image from "./img/getData.png";
import {Typography, useTheme} from "@mui/material";
import {BenefitSlide, bodyTextStyle, getBodyAccentTextStyle} from "./BenefitSlide";

function AccurateData() {
    const theme = useTheme();
    return <BenefitSlide image={image} title="Get data that is always accurate"
                         altText="Entering data into reports manually also takes up a significant amount of time and often results in errors.">
        <Typography sx={bodyTextStyle}>Machine learning and image recognition algorithms minimisze human error
            and <Typography as="span" sx={getBodyAccentTextStyle(theme)}>automate</Typography> both measurements and
            data entry.</Typography>
    </BenefitSlide>
}

export {AccurateData};