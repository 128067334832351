import {Box, Stack, Typography} from "@mui/material";

function FooterBlock({children, title, bottom}) {
    return <Stack direction="column"
                  justifyContent="space-between"
                  alignItems="flex-start"
                  sx={{
                      minWidth: "200px",
                      fontSize: "14px",
                      paddingBottom: {lg: "0px", xs: "40px"}
                  }}>
        <Box>
            <Typography sx={{
                fontSize: "16px",
                fontWeight: 600,
                color: "black",
                paddingBottom: "20px"
            }}>
                {title}
            </Typography>
            {children}
        </Box>
        <Box sx={{
            display: {lg: "block", xs: "none"}
        }}> {bottom}</Box>
    </Stack>
}

export {FooterBlock};