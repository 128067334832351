import {Box, Typography} from "@mui/material";

function AppPrivacy() {
    return <Box className="terms" sx={{
        paddingLeft: {lg: "98px", xs: "20px"},
        paddingRight: {lg: "98px", xs: "20px"},
    }}>
        <Typography sx={{textAlign: "center"}} variant="h1">Privacy Policy</Typography>
        <p className="document-meta">
            <strong>Logsizer Pro</strong><br/>
            Version: 1.0
        </p>
        <p>We would like to inform you about the processing of personal data carried out by Logsizer Pro (hereinafter
            referred to as
            "we") in accordance with the EU General Data Protection Regulation (GDPR). If you have any questions or
            comments
            about this data protection information, you can send them to the email address mentioned in Section 2 at any
            time.
            We have also implemented appropriate technical and organizational security measures in accordance with the
            EU GDPR
            to protect your personal data against loss, destruction, manipulation, and unauthorized access.
        </p>

        <ol>
            <li>
                <h2>Overview</h2>
                <p>The following data protection information informs you about the type and scope of the processing of
                    so-called
                    personal data by Logsizer Pro. Personal data is information that is directly or indirectly assigned
                    to you or
                    could be assigned to you.</p>
            </li>

            <li>
                <h2 id="contact">Name and Contact Information of the Data Controller and Data Protection Officer</h2>
                <br/>
                <p>

                    The entity responsible for the processing of your personal data is:<br/><br/>

                    PSA Fosize<br/>
                    Address: Cybernetyki 19B, lok. IIIP, Warsaw, 02-677, Poland<br/>
                    Tel.:<br/>
                    Email: contact@logsizer.pro<br/>
                    Managing Director: Pavel Boyarski
                </p>
                <p>For any data protection issues, please send us an email at contact@logsizer.pro</p>
                <p>Throughout this document, we refer to Logsizer Pro in the first person plural, i.e., as "we," "our,"
                    etc.</p>
            </li>

            <li>
                <h2>Data Processing Objectives, Legal Basis, and Legitimate Interests Pursued by Us or a Third Party,
                    and
                    Categories of Recipients</h2>
                <ol>
                    <li className="h3">
                        <h3>Use of the Logsizer Pro App</h3>
                        <ol>
                            <li className="h4">
                                <h4>Data Collection and Processing during Download and Use of Our App</h4>
                                <p>When downloading the mobile application, necessary information is transferred to the
                                    respective app store. We refer to the data protection statements of the respective
                                    store
                                    providers. You can find the stores' data protection information here:
                                    <p>
                                        <ul>
                                            <li>Google Play Store: [Google Privacy
                                                Policy](https://policies.google.com/privacy)
                                            </li>
                                            <li>Apple Store: [Apple Privacy
                                                Policy](https://www.apple.com/de/legal/privacy/)
                                            </li>
                                        </ul>
                                        <p>We have no influence over this data collection and are not responsible for
                                            it. We process the
                                            data only to the extent necessary to download the mobile application onto
                                            your mobile
                                            device.</p>
                                        <p>The app or the Play Store provide us with anonymous equipment data. If you
                                            choose to use our
                                            mobile application, we will have access to the following anonymous log
                                            files:</p>
                                        <ul>
                                            <li>IP Address</li>
                                            <li>Date and time of request</li>
                                            <li>Time zone difference to Greenwich Mean Time (GMT)</li>
                                            <li>Content of the request (specific page)</li>
                                            <li>Access status/HTTP status code</li>
                                            <li>Amount of data transferred in each case</li>
                                            <li>Amount of data transferred in each case</li>
                                            <li>Browser</li>
                                            <li>Operating system and its interface</li>
                                            <li>Hardware configuration of your device</li>
                                            <li>Language and version of your browser software</li>
                                        </ul>
                                        <p>This data collection is technically necessary to offer you the features of
                                            our mobile
                                            application and to ensure stability and security (legal basis — Art. 6 I S.
                                            1 let. f)
                                            GDPR).</p>
                                        <p>We also collect identification data from your device, the name of your mobile
                                            device, and
                                            email addresses.</p>
                                    </p>
                                </p>
                            </li>

                            <li className="h4">
                                <h4>Use of Camera and Library/Records/Photos</h4>
                                <p>When you start using our mobile application, we ask you for permission to use your
                                    camera.
                                    This permission is considered consent under Article 6(1)(a) of the GDPR. Your
                                    consent is
                                    voluntary.
                                    If you do not give us permission, we will not use this data. In this case, you will
                                    not be
                                    able to use all the features of our application. You can grant or revoke your
                                    consent at any
                                    time later in the settings oа operating system.
                                    If you have given us permission to access the camera, the mobile application will
                                    still only
                                    access your data to the extent necessary to ensure functionality.
                                </p>
                                <p>The legal basis for processing is Article 6(1)(a) GDPR (consent).</p>
                            </li>

                            <li className="h4">
                                <h4>Collection of Location Data</h4>
                                <p>Your timber data, for example about a pile of logs or timber, is provided in the
                                    application
                                    with GPS coordinates. You can only use these features after you have agreed that we
                                    can
                                    collect your location data via GPS and your IP address in an anonymous form for the
                                    purpose
                                    of providing the service. This consent is considered consent under Article 6(1)(a)
                                    of the
                                    GDPR. Your consent is voluntary. If you do not give your consent, you will not be
                                    able to
                                    use the GPS function. You can cancel and allow the function at any time in the
                                    application
                                    settings or your operating system.</p>
                                <p>Your location will only be transmitted to us if you use features in the application
                                    that we
                                    can only offer you if you know your location.
                                </p>
                                <p>The legal basis for processing is Article 6(1) Sections a) and b) GDPR.</p>
                            </li>

                            <li className="h4">
                                <h4>Use of Measurement Data</h4>
                                <p>When you start using our mobile application, we ask you for permission to use the
                                    data you
                                    obtained during measurement (report/records/photos). This permission is considered
                                    consent
                                    under Article 6(1)(a) of the GDPR. Your consent is voluntary.
                                    This data serves the purpose of improving and further developing the products and
                                    services
                                    we offer.
                                </p>
                                <p>If you do not give us permission, we will not use this data. In this case, you will
                                    be able
                                    to use all the features of our application. You can grant or revoke your consent at
                                    any time
                                    later in the application settings or operating system.</p>
                                <p>If you have given us permission to access your measurement data, the mobile
                                    application will
                                    still only access your data to the extent necessary to ensure functionality.</p>
                                <p>The legal basis for processing is Article 6(1)(a) GDPR (consent).</p>
                            </li>

                            <li className="h4">
                                <h4>User Profile Registration</h4>
                                <p>To collect data using our app, you need to register and create a user profile. During
                                    registration, your email address, password, first name, and last name are
                                    processed.</p>
                                <p>As soon as you make a purchase in the app, we receive information that you have made
                                    a
                                    purchase under your account.
                                    The purpose of the user profile is to provide you with the ability to use the
                                    scanning
                                    function, save results, and make purchases in the app.
                                </p>
                                <p>We regularly delete profiles of users of the free app that have been inactive for a
                                    long time
                                    to conserve data.
                                    You can also delete your user profile at any time by requesting removal using the
                                    provided
                                    contact information.
                                </p>
                                <p>The legal basis for processing is Article 6 I S. 1, points b) and f) GDPR.</p>
                            </li>
                            <li className="h4">
                                <h4>Error Report/Feedback</h4>
                                <p>To analyze errors, you are given the opportunity to send us an error report as
                                    feedback.
                                    The app's latest actions are saved in the feedback/log file (what input was made,
                                    what data
                                    was saved, the state of the device system, information about the operating system,
                                    hardware
                                    information). This serves for the constant improvement of the app.
                                </p>
                                <p>The legal basis for processing is Article 6 I S. 1, points a, b) and f) GDPR.</p>
                            </li>

                            <li className="h4">
                                <h4>Data Processing for Contract Execution</h4>
                                <p>If you download the app or make purchases in it, we process the data you provide or
                                    the data
                                    sent to us by the corresponding app or Play Store for contract processing. The data
                                    is
                                    transferred using SSL encryption and is processed in our database located in the EU.
                                    You can
                                    also find your purchase in the overview of your account in the corresponding app or
                                    game
                                    store. We act as the customer in relation to the App or Play Store; the App or Play
                                    Store
                                    acts as our sales partner. For orders that you place directly with us, Logsizer Pro
                                    is your
                                    contractual partner.</p>
                                <p>The legal basis for data processing is Article 6 I point b) GDPR, as processing is
                                    carried
                                    out to fulfill the contract with you, as well as with the app or Play Store. In
                                    addition,
                                    according to Article 6 I point f) GDPR, we have a legitimate interest in cooperating
                                    with
                                    leading app providers and Play Store, thus maximizing coverage.</p>
                                <p>We also transfer the data contained in invoices to our tax inspection for the
                                    purposes of
                                    financial accounting. Our legitimate interest in this, according to Article 6 I
                                    point f)
                                    GDPR, is efficient and qualified accounting by specialists and optimization of
                                    administrative efforts within our company. We also transfer your data contained in
                                    the
                                    invoice to tax authorities to fulfill our legal obligations in accordance with
                                    Article 6 I
                                    point c) GDPR.</p>
                                <p>Information about the processing and deletion of personal data by app providers and
                                    Play
                                    Store can be found in the privacy statements of the respective store providers:
                                    Google Play Store: https://policies.google.com/privacy.
                                </p>
                            </li>

                            <li className="h4">
                                <h4>Data Retention Period</h4>
                                <p>We store data or documents only as long as necessary for the purposes of processing,
                                    and then
                                    immediately delete them unless deletion contradicts contractual or legal retention
                                    obligations (in particular, applicable tax and commercial retention obligations). We
                                    will
                                    carefully review which of your personal data falls under such retention obligations
                                    and will
                                    only store them to the necessary extent. We may still need your data to protect our
                                    legitimate interests under Article 6 I, point f) GDPR, to assert or defend against
                                    any legal
                                    claims related to the contract between you and us.</p>
                            </li>
                        </ol>
                    </li>
                    <li className="h3">
                        <h3>Newsletter Subscription</h3>
                        <p>You can subscribe to our newsletter to regularly receive current offers, dates, advertising,
                            or
                            similar information about us by email. Explicit consent is required for this. We use the
                            double-opt-in procedure: you first enter your name and email address, optionally with
                            additional
                            interests, and after registration, you will receive an email informing you of the newsletter
                            registration and asking you to confirm it via the provided link. This serves, among other
                            things,
                            the purpose of preventing unauthorized use of third-party email addresses. Your
                            registration,
                            confirmation, or cancellation is logged by us, meaning that your IP address and the time of
                            registration and confirmation or cancellation are saved so that we can prove your consent
                            complies
                            with legal requirements, which corresponds to our legitimate interests under Article 6 I,
                            point f)
                            GDPR. Legal basis: Article 6 I point a) GDPR, etc. We pass on the email address you
                            provided, and if
                            applicable, your name and optionally your interests to our newsletter delivery service
                            provider.
                            This is based on a so-called data processing contract in accordance with the provisions of
                            Article
                            28 GDPR. The newsletters contain so-called web beacons. These are transparent image files
                            that are
                            loaded from the provider's server when you read the newsletter online and whose loading is
                            registered. We also determine whether you read the newsletter and when and which links you
                            click, if
                            any. We use the information transmitted here, including your IP address, your provider, and
                            therefore your country of origin, browser, and operating system, as well as the time of
                            access to
                            optimize the newsletter service and adapt our content to your reading behavior and your
                            interests.
                            Our legitimate interest in using this service according to Article 6 I, point f) GDPR, is to
                            be able
                            to adapt our offer and advertising according to your usage.</p>
                        <p>Your consent statement is entirely voluntary and can be revoked by you at any time. To do
                            this, click
                            the "unsubscribe" link in one of the newsletters or inform us of the revocation in another
                            way using
                            the contact details provided. If you unsubscribe, we will delete your data or restrict
                            processing by
                            adding them to a blacklist.</p>
                    </li>

                    <li className="h3">
                        <h3>Contact</h3>
                        <p>If you contact us by phone, email, mail, through contact forms or in another way with a
                            question, or
                            we contact you, we will also process your personal data, such as your name, address, phone
                            number,
                            and content of the message. This information is processed solely for the purpose of properly
                            handling your request. Your data will be used only to the necessary extent. The legal basis
                            for
                            processing the above-described data is Article 6 I 1 letter b GDPR, as this is related to
                            the
                            conclusion or performance of a contract with you; otherwise, Article 6 I 1 letter is
                            interested in
                            correspondence with you on the matter.</p>
                        <p>The contact data will be deleted if they are no longer needed for communication with you and
                            legally
                            prescribed retention periods have expired.</p>
                        <p>We process data on a server in the EU. We would like to note that full data security cannot
                            be
                            guaranteed when communicating by email. In the case of unencrypted emails, despite transport
                            encryption, there is a likelihood that unauthorized third parties could gain access to
                            them.</p>
                    </li>

                    <li className="h3">
                        <h3>Business Contacts and Interested Parties</h3>
                        <p>Below, we would like to inform you about how we process your data if you correspond with us
                            on
                            business matters or make an inquiry regarding our products.</p>
                        <ol>
                            <li className="h4">
                                <h4>What Data Do We Process?</h4>
                                <p>We process basic data and contact information of our business contacts and interested
                                    parties, including, in particular, the following personal data:</p>
                                <ul>
                                    <li>Name and address</li>
                                    <li>As voluntary information:
                                        <ul>
                                            <li>Phone numbers (landline and mobile, if available)</li>
                                            <li>Fax number</li>
                                            <li>Email address (if available)</li>
                                            <li>Your bank details (for invoicing, if applicable)</li>
                                        </ul>
                                    </li>
                                </ul>
                                <p>On the one hand, we process data provided directly by you as a business contact or
                                    interested
                                    party, and on the other hand, we also process publicly available contact
                                    information, for
                                    example, from websites. No personal data is required by law or contract, except when
                                    we must
                                    issue you an invoice or you must issue us an invoice; then the invoice must contain
                                    the
                                    necessary information (in particular, your full name and the complete address of
                                    your
                                    company). If we cannot process this data, we will not be able to issue invoices to
                                    you or
                                    make payments to you properly.</p>
                            </li>

                            <li className="h4">
                                <h4>For What Purpose Do We Process Your Data?</h4>
                                <p>We process personal data of business contacts and interested parties.
                                    For interested parties, data processing occurs for the purpose of timely
                                    communication with
                                    you and providing you with the information you request. The legal basis for data
                                    processing
                                    is Article 6 I paragraph f) GDPR (legitimate interest) and Article 6 I paragraph b)
                                    GDPR
                                    (for the purpose of contract conclusion).
                                </p>
                                <p>If you enter into a contract with us as a business contact, for example, about the
                                    delivery
                                    of goods, provision of services, or creation of work, we process the data you
                                    provide for
                                    the purpose of contract processing. On the one hand, the legal basis for business
                                    contacts
                                    is Article 6 I b) GDPR (initiation or performance of a contract), and on the other
                                    hand,
                                    Article 6 I f) GDPR (legitimate interest) to be able to assert any claims or
                                    counterclaims
                                    arising from the contract within the statute of limitations.</p>
                                <p>In some cases, data processing goes beyond the above-mentioned purpose in accordance
                                    with
                                    Article 6 I c) GDPR (legal obligation) if it is necessary for us to comply with our
                                    obligations under tax and commercial law and retention periods.
                                    The processing of publicly available data is also carried out to achieve our
                                    corporate goals
                                    based on Article 6 I f) GDPR (legitimate interest).
                                </p>
                            </li>

                            <li className="h4">
                                <h4>Further Processing and Transfer of Data</h4>
                                <ol>
                                    <li className="h5">
                                        <h5>Direct Mail Marketing</h5>
                                        <p>We process your personal data for the purpose of direct advertising based on
                                            our
                                            legitimate interest in marketing and informing about our services and
                                            products in
                                            accordance with Article 6 I paragraph f) GDPR, or, if required by law, based
                                            on your
                                            consent under Article 6 I paragraph a) GDPR.</p>
                                        <p>For direct advertising purposes, we may send you information by mail and, if
                                            allowed
                                            by Section 7 III UWG, by email. Any further direct advertising measures will
                                            only be
                                            carried out with your consent. We transfer the required data (name and
                                            address,
                                            optionally email address) in encrypted form to a service provider who
                                            processes them
                                            on our behalf to send promotional mail (information about new app features
                                            and new
                                            software products from our company, invitations to events, and training
                                            offers,
                                            invitations to anniversaries, etc.). You can object at any time to the
                                            storage and
                                            use of your data for this purpose, using the contact information provided in
                                            section
                                            2.
                                        </p>
                                        <p>If we entrust a third party with sending us direct advertising, this will be
                                            done
                                            based on a so-called "order processing contract" in accordance with the
                                            terms of
                                            Article 28 GDPR.</p>
                                    </li>
                                    <li className="h5">
                                        <h5>Tax Inspection</h5>
                                        <p>We may also transfer invoices from your company, which may contain your
                                            company’s
                                            contact data, or our invoices to your company, which were sent to you, to
                                            the tax
                                            inspection for financial accounting purposes. Our legitimate interest in
                                            this is the
                                            efficient and qualified financial accounting by specialists and optimization
                                            of
                                            administrative work within our company under the meaning of Article 6 I
                                            paragraph f)
                                            GDPR. In addition, data contained in the invoices may be transferred to the
                                            tax
                                            inspection. This is done to fulfill our legal obligations, therefore the
                                            legal basis
                                            for this is Article 6 I paragraph c) GDPR.</p>
                                    </li>
                                    <li className="h5">
                                        <h5>Credit Institution or Bank</h5>
                                        <p>We transfer payment data provided for payment processing in encrypted form to
                                            the
                                            payment service provider you have selected or ordered by us, or the credit
                                            institution responsible for the payment. They process your data and the
                                            privacy
                                            statement of the respective payment service provider applies in accordance
                                            with the
                                            requirements of the responsible person. The legal basis for data processing
                                            is
                                            Article 6 I paragraph b) GDPR.</p>
                                    </li>
                                </ol>
                            </li>

                            <li className="h4">
                                <h4>Storage Period</h4>
                                <p>We store data or documents only as long as necessary for the purposes of processing
                                    and then
                                    immediately delete them unless deletion contradicts contractual or legal storage
                                    obligations
                                    (in particular, applicable tax and commercial storage obligations). We will
                                    carefully check
                                    which of your personal data falls under such storage obligations and will store them
                                    only to
                                    the necessary extent.
                                </p>
                                <p> We may still need your data to protect our legitimate interests in the sense of
                                    Article 6 I,
                                    paragraph f) GDPR, to be able to assert or defend against any legal claims in
                                    connection
                                    with the contract between you and us.</p>
                            </li>
                        </ol>
                    </li>

                    <li className="h3">
                        <h3>Statistical Evaluation</h3>
                        <p>We also process data mentioned in section 3.1 for statistical analysis. They serve the
                            purpose of
                            improving and further developing the products and services we offer. For creating
                            evaluations,
                            personal data is only used to the extent necessary for the aforementioned purposes. As far
                            as
                            possible, data is pseudonymized or anonymized before use. The results of the evaluations are
                            used
                            only in aggregated form. Client-related evaluations are not carried out unless the client
                            has given
                            explicit consent for this. The legal basis for the processing of the aforementioned data is
                            Article
                            6 I paragraph f GDPR.</p>
                    </li>
                </ol>
            </li>

            <li>
                <h2>Data Transfer</h2>
                <p>We do not transfer your data to third parties.</p>
            </li>

            <li>
                <h2>Your Rights</h2>
                <ol>
                    <li className="h3">
                        <h3>Overview</h3>
                        <p>In addition to the right granted to us to revoke your consent, you have the following
                            additional
                            rights if the corresponding legal requirements are met:</p>
                        <ol>
                            <li className="h4">
                                <h4>Right to Information</h4>
                                <p>You have the right to request confirmation of whether we process personal data
                                    concerning you
                                    and the right to information about your personal data that we process in accordance
                                    with
                                    Article 15 GDPR.</p>
                            </li>
                            <li className="h4">
                                <h4>Right to Rectification</h4>
                                <p>You have the right to correct or supplement your data in accordance with Article 16
                                    GDPR.</p>
                            </li>
                            <li className="h4">
                                <h4>Right to Deletion</h4>
                                <p>You have the right to immediate deletion of your personal data (the right to be
                                    forgotten) in
                                    accordance with Article 17 GDPR, if one of the following reasons applies:</p>
                                <ul>
                                    <li>Personal data is no longer needed for the purposes for which it was collected or
                                        otherwise processed.
                                    </li>
                                    <li>You object to the processing of personal data for direct marketing purposes.
                                    </li>
                                    <li>You object to data processing based on our legitimate interest for reasons
                                        arising from
                                        your particular situation, and there are no overriding legal reasons for
                                        processing.
                                    </li>
                                    <li>Personal data has been processed unlawfully.</li>
                                    <li>Deleting your personal data is necessary to fulfill a legal obligation.</li>
                                </ul>
                                <p>Please note that legal or contractual obligations in accordance with Article 17(1)(3)
                                    of the
                                    GDPR may prevent the deletion of your personal data.</p>
                            </li>

                            <li className="h4">
                                <h4>Right to Restriction</h4>
                                <p>You have the right to restrict the processing of your personal data under Article 18
                                    of the
                                    GDPR if one of the following conditions is met:</p>
                                <ul>
                                    <li>You contest the accuracy of your personal data for a period enabling us to
                                        verify the
                                        accuracy of the personal data.
                                    </li>
                                    <li>The processing of your personal data is unlawful, but you oppose the erasure of
                                        the
                                        personal data.
                                    </li>
                                    <li>We no longer need your personal data for processing purposes but are required by
                                        you for
                                        the establishment, exercise, or defense of legal claims.
                                    </li>
                                    <li>You object to processing based on our legitimate interests, pending verification
                                        whether
                                        our legitimate grounds override yours.
                                    </li>
                                </ul>
                                <p>If the processing is restricted, we will only process the personal data in question
                                    with your
                                    consent or to a very limited extent (e.g., establishment and enforcement of legal
                                    claims,
                                    etc.).</p>
                            </li>

                            <li className="h4">
                                <h4>Right to Data Portability</h4>
                                <p>You have the right to data portability in accordance with Article 20 of the GDPR.</p>
                            </li>

                            <li className="h4">
                                <h4>Revocation of Consent-Based Processing</h4>
                                <p>If we are processing your personal data for specific purposes based on your consent
                                    and you
                                    have provided the respective consent declaration, you have the right to withdraw
                                    your
                                    consent at any time with future effect. The withdrawal does not affect the legality
                                    of the
                                    processing carried out up to the point of revocation. </p>
                            </li>

                            <li className="h4">
                                <h4>How to Exercise Your Rights</h4>
                                <p>You can exercise all of your aforementioned rights, for example, by sending us an
                                    email at
                                    contact@logsizer.pro. Further contact details can be found here.</p>
                            </li>

                            <li className="h4">
                                <h4>Right to File a Complaint</h4>
                                <p>You also have the right to lodge a complaint with the supervisory authority
                                    responsible for
                                    us. You can file a complaint with the supervisory authority at your place of
                                    residence.</p>
                            </li>
                        </ol>
                    </li>

                    <li className="h3">
                        <h3>Right to Object</h3>
                        <p>In accordance with Article 21(1)(1) of the GDPR, you may object to data processing for
                            reasons
                            arising from your specific situation. The aforementioned general right to object applies to
                            all
                            processing objectives described in this data protection information, processed based on
                            Article
                            6(1)(f) of the GDPR. Unlike the specific right to object to data processing for advertising
                            purposes, we are obligated to implement such a general objection only if you provide us with
                            overriding reasons.</p>
                    </li>
                </ol>
            </li>
            <li>
                <h2>Changes to This Policy</h2>
                <p>We will review this privacy policy if there are changes to this website or in other instances when it
                    is
                    required. The most current version can always be found on this site. Therefore, you should regularly
                    visit
                    this website to stay updated on the current status of the data protection declaration.</p>
            </li>
        </ol>
    </Box>
}

export {
    AppPrivacy
};