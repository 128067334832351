import {Grid, Typography} from "@mui/material";
import bg from "../img/efficiency.png";
import {GetTheAppButton} from "./buttons/GetTheAppButton";

function GetTheApp() {
    return <Grid container direction="row"
                 alignItems="center"
                 sx={{
                     marginTop: "100px",
                     background: `url(${bg}) no-repeat`,
                     height: "620px",
                     color: "white",
                     justifyContent: {
                         lg: "flex-end",
                         xs: "center"
                     }
                 }}>
        <Grid item lg={6} sx={{
            paddingRight: {
                lg: "205px",
                xs: "20px"
            },
            paddingLeft: {
                lg: "0px",
                xs: "20px"
            },
            textAlign: {
                lg: "left",
                xs: "center"
            }
        }}>
            <Typography variant="caption" sx={{
                paddingBottom: "32px",
                display: "inline-block",
            }}>
                Automate measurements, boost your efficiency
            </Typography>

            <GetTheAppButton/>

        </Grid>

    </Grid>
}

export {GetTheApp};