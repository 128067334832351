import {useEffect} from "react";

function Analytics({cookiesAccepted}) {
    useEffect(() => {
        window.dataLayer = window.dataLayer || [];

        function gtag() {
            // eslint-disable-next-line no-undef
            dataLayer.push(arguments);
        }

        if (cookiesAccepted) {
            gtag('consent', 'update', {
                ad_user_data: 'denied',
                ad_personalization: 'denied',
                ad_storage: 'denied',
                analytics_storage: 'granted'
            });
            gtag('js', new Date());
            gtag('config', 'G-NWSRWM5CVM');
        } else {
            gtag('consent', 'default', {
                ad_user_data: 'denied',
                ad_personalization: 'denied',
                ad_storage: 'denied',
                analytics_storage: 'denied',
                wait_for_update: 500,
            });
        }
    }, [cookiesAccepted]);

    return <></>;
}

export {Analytics}