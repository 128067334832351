import {Box, Typography} from "@mui/material";

function DetailItem({children, name}) {
    return <Box>
        <Typography as="span" sx={{
            fontWeight: 600,
            fontSize: "14px"
        }}>{name}</Typography> <Typography as="span" sx={{
        fontWeight: 300
    }}>{children}</Typography>
    </Box>
}

export {DetailItem};