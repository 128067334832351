import {Box, Typography} from "@mui/material";

function TermsSection({title, children}) {
    return <Box sx={{marginBottom: "56px"}}>
        {title ? <Typography sx={{
            display: "block",
            marginBottom: "16px"
        }} variant="h2">{title}</Typography> : ""}
        {children}
    </Box>
}

export {TermsSection};