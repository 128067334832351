import {red} from '@mui/material/colors';
import {createTheme} from '@mui/material/styles';


const greenStart = '#04766B';
const greenMiddle = '#089F6E'
const greenEnd = '#0DCA73';
const orange = '#F57F16';
const orangeGreen = '#009688';
const greenTextOnDark = '#10E875';

const lightText = "#606060";


// A custom theme for this app
const theme = createTheme({

    palette: {
        primary: {
            main: '#03766A',
        },
        secondary: {
            main: '#19857b',
            light: '#F2F9FF',
        },
        error: {
            main: red.A400,
        },
        gradients: {
            greenStart: greenStart,
            greenMiddle: greenMiddle,
            greenEnd: greenEnd,
            orange: orange,
            orangeGreen: orangeGreen,
        },
        roadmapBg: "#F9FAFB",
        text: {
            primary: '#212020',
            light: lightText,
            accentOnDark: greenTextOnDark,
        },
        borders: {
            inactive: "#C9D6E1",
        }
    },

    typography: {
        fontFamily: "Inter",

        h1: {
            fontFamily: 'Barlow',
            fontSize: '52px',
            fontWeight: 500,
            lineHeight: "55.65px",
        },
        h2: {
            fontSize: "24px",
            fontWeight: 800,
            textTransform: "uppercase"
        },
        h3: {
            fontFamily: "Barlow",
            fontSize: "36px",
            fontWeight: 300,
            textAlign: "center",
        },
        h3b: {
            fontWeight: 500,
        },

        h3i: {
            fontFamily: 'jsMath-cmmi10',
            fontStyle: "italic",
            fontWeight: 500
        },

        h4: {
            fontWeight: 500,
            textTransform: "uppercase",
            fontSize: "20px",

        },

        h5: {
            fontSize: "14px",
            textTransform: "uppercase",
            fontWeight: 500,
        },

        h5Feature: {
            fontSize: "14px",
            fontWeight: 400,
        },

        body1: {
            fontSize: "16px",
            color: "black",
            fontWeight: 400,
            /*marginTop: "10px",
            marginBottom: "10px",*/
        },

        body2: {
            fontSize: "16px",
            color: "black",
            fontWeight: 400
        },

        body3: {
            fontFamily: "inter",
            fontSize: "16px",
            color: "black",
            fontWeight: 400,
            marginTop: "10px",
            marginBottom: "10px",
        },

        body2Accent: {
            background: `linear-gradient(to bottom right, ${greenStart}, ${greenMiddle})`,
            backgroundClip: "text",
            color: "transparent",
        },

        caption: {
            fontSize: "30px",
            fontWeight: 400,
        },


        accent: {
            fontFamily: 'jsMath-cmmi10',
            fontSize: '52px',
            fontStyle: 'italic',
            fontWeight: 500,
        }
    },

    sizes: {
        containerWidth: "1280px"
    },
    components: {
        MuiTypography: {
            defaultProps: {
                variantMapping: {
                    body3: "p",
                }
            }
        }
    }

});

export default theme;
