import {Box, Stack, Typography, useTheme} from "@mui/material";

function BenefitSlide({image, title, children, altText}) {
    const theme = useTheme();
    return <Stack direction="column" sx={{
        background: `url(${image}) center no-repeat`,
        backgroundSize: "cover",
        paddingTop: "46px",
        paddingBottom: "46px",
        paddingLeft: "22px",
        paddingRight: "22px",
        borderRadius: "15px",
        minHeight: "285px",
        marginBottom: "40px",
    }}>
        <Typography variant="h4" sx={{
            color: "white",
            marginBottom: "12px"
        }}>{title}</Typography>
        <Box sx={{marginBottom: "32px"}}>
            {children}
        </Box>
        <Typography sx={{
            borderLeft: `2px solid ${theme.palette.text.accentOnDark}`,
            color: "#D9D9D9",
            fontSize: "16px",
            fontWeight: 400,
            paddingLeft: "12px",
        }}>
            {altText}
        </Typography>
    </Stack>
}

const bodyTextStyle = {
    color: "white",
    fontSize: "16px",
    fontWeight: 400
};

function getBodyAccentTextStyle(theme) {
    return {
        color: theme.palette.text.accentOnDark,
        fontWeight: 600
    }
}

export {BenefitSlide, bodyTextStyle, getBodyAccentTextStyle};