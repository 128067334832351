import {NewTab} from "./NewTab";
import {ToggleOn} from "./ToggleOn";
import {Camera} from "./Camera";
import {Chart} from "./Chart";
import {Send} from "./Send";
import {Stack, useTheme} from "@mui/material";
import {useState} from "react";

const TAB_SELECT = "select";
const TAB_CAPTURE = "capture";
const TAB_GENERATE = "generate";
const TAB_SEND = "send";
const indexMap = [];
indexMap[TAB_SELECT] = 0;
indexMap[TAB_CAPTURE] = 1;
indexMap[TAB_GENERATE] = 2;
indexMap[TAB_SEND] = 3;


function Tabs({onSelectedIndexChange}) {
    const theme = useTheme();
    const [activeTab, setActiveTab] = useState(TAB_SELECT);
    const tabClick = tabId => (event) => {
        onSelectedIndexChange(indexMap[tabId]);
        setActiveTab(tabId);
    }
    return <>
        <Stack direction="row" justifyContent="space-between" spacing={1} sx={{
            alignItems: "center",
            marginBottom: "20px"
        }}>

            <NewTab TitleIcon={ToggleOn} title="Select" isActive={activeTab === TAB_SELECT}
                    onClick={tabClick(TAB_SELECT)}>
                Open the app and select the measurement details
            </NewTab>
            <NewTab TitleIcon={Camera} title="Capture" isActive={activeTab === TAB_CAPTURE}
                    onClick={tabClick(TAB_CAPTURE)}>
                Take a photo of the log stack with a mobile phone
            </NewTab>
            <NewTab TitleIcon={Chart} title="Generate" isActive={activeTab === TAB_GENERATE}
                    onClick={tabClick(TAB_GENERATE)}>
                Get the results in an Excel data table
            </NewTab>
            <NewTab TitleIcon={Send} title="Send" isActive={activeTab === TAB_SEND} onClick={tabClick(TAB_SEND)}>
                Share the report
            </NewTab>
        </Stack>

    </>
}

export {Tabs}