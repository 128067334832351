import {Box, Typography} from "@mui/material";
import "./styles.scss";
import {InnerLink} from "../components/InnerLink";

function TermsPage() {
    return <Box className="terms" sx={{
        paddingLeft: {lg: "98px", xs: "20px"},
        paddingRight: {lg: "98px", xs: "20px"},
    }}>
        <Typography sx={{textAlign: "center"}} variant="h1">LogSizerPro Terms and Conditions</Typography>
        <p className="document-meta">
            Version: 1.2
        </p>
        <ol>
            <li>
                <h2>Interpretation</h2>
                <ol>
                    <li id="interpretation_1">The following terms, when capitalised, shall have the meanings assigned to
                        them
                        below:
                        <dl>
                            <dt>Agreement</dt>
                            <dd>The contract between the Parties, comprising these end-user licence terms (as amended
                                from time
                                to time) and such other terms concerning the Service as the Parties may agree to
                            </dd>

                            <dt>Content</dt>
                            <dd>The information and technical resources provided by the Supplier to its customers for
                                their use
                                in conjunction with the Software, and the features of the Software itself. Items of
                                Content are
                                usually grouped into standard packages referred to as Service Plans
                            </dd>

                            <dt>Customer</dt>
                            <dd>Any person or entity who has a valid User Account or maintains a full or partial copy of
                                any
                                Software. Each Customer should take this term as referring specifically to them, unless
                                the
                                context otherwise requires
                            </dd>

                            <dt>Customer Data</dt>
                            <dd>Any information, including but not limited to Personal Data, images, measurements, and
                                other
                                that the Customer or an End User collects, enters, records, stores, uses, controls,
                                modifies,
                                arranges, deletes, discloses, makes available, transmits or otherwise processes through
                                or by
                                means of the Service
                            </dd>

                            <dt>End User</dt>
                            <dd>Any person whom the Customer permits or causes to have access to the Service or any part
                                thereof, whether through the User Account or otherwise (including anyone who downloads
                                the
                                Software or uses or accesses the Service on the Customer’s behalf)
                            </dd>

                            <dt>Intellectual Property</dt>
                            <dd>All existing and future trademarks, service marks, domain names and business names,
                                brands,
                                rights pertaining to inventions, designs, databases and proprietary information
                                (including,
                                without limitation, trade secrets and know-how), patents, copyrights, data rights, moral
                                rights
                                and all other items (including rights and other benefits) commonly regarded as
                                intellectual
                                property, whether registered or not
                            </dd>

                            <dt>Party</dt>
                            <dd>Each of the Customer and the Supplier (collectively, "the Parties")</dd>

                            <dt>Personal Data</dt>
                            <dd>Any information relating to an identified or identifiable natural person, regardless of
                                the
                                state and form of such information
                            </dd>

                            <dt>Representative</dt>
                            <dd>Any person who represents the Customer upon its entry into the Agreement</dd>

                            <dt>Service</dt>
                            <dd>Depending on the context, either (a) the Supplier's making available of the Software,
                                the User
                                Account, Content and customer support, or (b) the Software, the User Account, Content
                                and
                                customer support collectively or in any combination
                            </dd>

                            <dt>Service Plan</dt>
                            <dd>A standard set of Content that the Supplier has agreed to provide</dd>

                            <dt>Software</dt>
                            <dd>Fosize timber measuring software and such other Supplier-provided programs as the
                                Supplier may
                                make available in conjunction therewith, including such patches, updates, upgrades,
                                other
                                modifications and replacements thereof as the Supplier may from time to time provide
                            </dd>

                            <dt>Supplier</dt>
                            <dd>PSA Fosize <br/> Warsaw, Cybernetyki, 19B, lok. IIIP, 02-677 <br/>
                                <InnerLink href="mailto:contact@logsizer.pro">contact@logsizer.pro</InnerLink></dd>


                            <dt>User Account</dt>
                            <dd>A Supplier-provided user account whose purpose is to allow the Customer to access and
                                use
                                Content (or certain Content) and engage in certain data processing activities
                            </dd>

                        </dl>
                    </li>

                    <li>The words "herein", "hereto", "hereof", "hereunder", "hereby" and "herewith" refer to this
                        Agreement.
                        Words denoting a gender or genders shall be read as referring to all genders, unless the context
                        otherwise requires.
                    </li>

                    <li>This Agreement (as amended from time to time) constitutes the entire agreement between the
                        Parties
                        relating to the subject matter hereof and supersedes all prior agreements and understandings
                        between the
                        Parties with respect to that subject matter.
                    </li>

                    <li>In case of conflict between any provision herein and any statement, representation or other
                        information
                        published on the Supplier’s website or contained in any other materials or communications, the
                        provision
                        in the Agreement shall control.
                    </li>
                </ol>
            </li>

            <li>
                <h2>Commencement</h2>
                <ol>
                    <li>This Agreement shall be effective between the Parties as of the moment when the person or entity
                        to be
                        identified as the Customer downloads or installs any part of the Software, obtains a User
                        Account or
                        agrees or is deemed to have agreed to the terms hereof, whichever occurs first.
                    </li>
                    <li>Anyone who uses, accesses or attempts to use or access any part of the Service shall by so doing
                        be
                        deemed to have agreed to the terms hereof.
                    </li>
                </ol>
            </li>

            <li>
                <h2>Licence</h2>
                <ol>
                    <li>Subject to the terms set forth herein, the Supplier grants to the Customer and the latter
                        accepts, for
                        the term hereof, a limited, non-exclusive and non-transferable (save as provided in section
                        3.2) licence to use such features of the Service as included in the Customer’s Service Plan.
                    </li>
                    <li id="license_1">
                        With respect to assignment, sub-licensing and other disposals, the Parties have agreed that:
                        <ol>
                            <li>
                                The Customer shall not, without the Supplier’s prior explicit consent, sub-license,
                                assign,
                                encumber or otherwise dispose of any of its rights or obligations hereunder, except
                                that:
                                <ol>
                                    <li>The foregoing restriction does not apply to the Customer’s disposal of its
                                        monetary
                                        claims (i.e., claims for a specific amount of money to be paid to the Customer)
                                        and
                                    </li>
                                    <li>The Customer may assign the entire Agreement, i.e. all its rights and
                                        obligations
                                        hereunder, by way of general succession or enterprise transfer (the assuming
                                        party,
                                        respectively, being either the Customer’s general successor or the acquirer of
                                        the
                                        Customer’s enterprise to which this Agreement pertains)
                                    </li>
                                </ol>
                            </li>
                            <li>
                                The Supplier may:
                                <ol>
                                    <li>In its sole discretion sub-license, assign, encumber and otherwise dispose of
                                        any and
                                        all of its rights hereunder
                                    </li>
                                    <li id="license_2">Dispose, in any manner that the Supplier reasonably deems
                                        appropriate, of
                                        any and all of its obligations hereunder, provided it notifies the Customer
                                        thereof.
                                    </li>
                                    <li>Assign this Agreement as provided under point 3.2.2.2 of this subsection.</li>
                                </ol>
                            </li>
                        </ol>
                    </li>
                    <li>The Service may only be accessed through the interface(s) that the Supplier has provided
                        therefore and
                        must not be accessed or attempted to be accessed in any manner not approved by the Supplier.
                    </li>
                    <li>The Customer may make copies of the Software for its own installation, archival and backup
                        purposes
                        only.
                    </li>
                    <li>As between the Parties, all Service-related Intellectual Property shall belong to the Supplier
                        and its
                        licensors. The Customer shall not acquire any right to such Intellectual Property or otherwise
                        in
                        connection with the Service, except for the limited rights of use expressly set forth in this
                        Agreement.
                        All rights not expressly granted herein shall be deemed withheld.
                    </li>
                </ol>
            </li>

            <li>
                <h2>Service</h2>
                <ol>
                    <li>The Supplier will use commercially reasonable efforts to provide the Service to the Customer
                        throughout
                        the term of this Agreement in accordance with the Customer’s Service Plan.
                    </li>
                    <li>The Customer acknowledges that:
                        <ol>
                            <li>The Service has not been designed to meet the Customer’s individual requirements.</li>
                            <li>The operation of the Service may from time to time encounter technical or other problems
                                and may
                                not continue uninterrupted or without errors.
                            </li>
                            <li>The Service is not fault-tolerant and has not been designed for use in inherently
                                dangerous
                                circumstances, such as, e.g., the operation of “major sources of danger”, traffic
                                control or
                                life support systems, handling hazardous substances and other activities where the
                                failure of
                                the Service could lead to death, personal injury or environmental damage.
                            </li>
                        </ol>
                    </li>
                    <li>The Service is provided on an “as is” and “as available” basis. The Customer’s selection and use
                        of the
                        Service is at the Customer’s own risk, and so are the Customer’s and End Users’ exposure to,
                        down- and
                        upload of, as well as transmission, other processing and possession of information, programs and
                        other
                        items through or due to the Service.
                    </li>
                    <li>The Supplier has no obligation to enhance, modify or replace any part of the Service or to
                        continue
                        developing or releasing new versions thereof.
                    </li>
                    <li>The Supplier aims to respond to support requests within 24 business hours but makes no
                        commitment as to
                        how quickly support will be provided or issues will be resolved.
                    </li>
                    <li>The Service may provide links or access to third-party websites, resources or services and these
                        may
                        provide links or access to the Service. The Supplier is not responsible for the qualities
                        (including the
                        availability, reliability and security) of such external sites, resources or services, does not
                        endorse
                        them and shall not be liable for any loss, damage, expenses or other consequences resulting from
                        their
                        existence, absence, qualities, use or inability to use them.
                    </li>
                    <li>The Service may depend on availability of a working wi-fi internet connection or cellular data
                        connection.
                        The Supplier is not responsible for the speed or reliability of internet or cellular service
                        providers.
                        The supplier is not responsible for Service delays, Service interruptions or other types of
                        Service
                        problems that result from internet or cellular data providers’ inability or delay to provide
                        access to
                        the internet.It is the Customer’s obligation to secure a reliable access to the wireless
                        internet.
                    </li>
                </ol>
            </li>

            <li>
                <h2>Payment</h2>
                <ol>
                    <li>By downloading or installing any part of the Software, obtaining a User Account or otherwise
                        subscribing
                        to the Service or any part thereof, or permitting or causing any such activity to be undertaken
                        on its
                        behalf, the Customer shall be deemed to have agreed to and accepted liability for the payment of
                        all
                        fees and other charges associated with the applicable Service Plan. The same applies, mutatis
                        mutandis,
                        where the Customer permits or causes itself to be designated as the payor for someone else’s
                        Service
                        Plan.
                    </li>
                    <li>Unless otherwise specifically agreed, the billing cycle for any chargeable Service Plan is
                        monthly
                        (i.e., the Customer is charged for the Service on a monthly basis), commencing on the day when
                        the
                        subscription to the respective plan takes effect, or, if the subscription includes a free trial
                        period,
                        on the first day following such trial.
                    </li>
                    <li>Payment for the Service is due in advance by the first day of each billing cycle and must be
                        effected by
                        means of a payment card (credit or debit) or an online wallet service accepted by the Supplier
                        (such as
                        Google Wallet), unless otherwise agreed. The Customer shall ensure that sufficient funds are
                        available
                        on the relevant account and acknowledges that late payment may result in the suspension of
                        Service or
                        termination of the Agreement.
                    </li>
                    <li>All payments for the Service are handled by a third-party payment gateway. The Supplier is not
                        responsible for the processing of the Customer’s payments and shall not be liable for any matter
                        in
                        connection therewith.
                    </li>
                    <li>The Supplier may change the fees, rates and the billing cycle applicable to the Customer’s
                        Service Plan
                        upon 30 days notice. In the event that the Customer does not agree with the respective
                        change(s), its
                        sole and exclusive remedy shall be to unsubscribe from the Service Plan in question. The
                        Customer’s
                        remaining subscribed to the Service Plan after any such change(s) shall be deemed to constitute
                        its
                        consent to the respective change(s).
                    </li>
                    <li>The Customer acknowledges that Service fees are non-refundable. For instance:
                        <ol>
                            <li>If the Customer unsubscribes from a Service Plan or subscription is modified
                                or the Agreement is terminated or modified in the middle of a billing period, no refund
                                will be
                                given to the Customer for any payment relating to that billing period.
                            </li>
                            <li>Payments attributable to future billing periods will not be refunded, unless otherwise
                                explicitly agreed.
                            </li>
                        </ol>
                    </li>
                    <li>The Supplier's fees and rates are exclusive of value added tax, sales tax and other public
                        burdens, save
                        where the Supplier has otherwise explicitly stated. The Customer shall be solely responsible for
                        all
                        taxes, duties and burdens that may be levied on its purchase, import, export, or use of the
                        Service.
                    </li>
                    <li>All sums payable to the Supplier hereunder shall be paid in full, without deducting or allowing
                        the
                        deduction of any currency conversion, wire transfer, remittance or other charges relating to the
                        payment
                        (or any handling of the payment) thereof.
                    </li>
                </ol>
            </li>

            <li>
                <h2>Customer’s undertaking</h2>
                <ol>
                    <li>The Customer must be a person (natural or legal) or an entity with legal capacity.</li>
                    <li>The Customer must comply and shall cause each End User to comply with all laws, rules and
                        regulations
                        applicable to their use of the Service and their processing of Customer Data.
                    </li>
                    <li>The Customer warrants that it will not use the Service for sending unsolicited communications or
                        for
                        uploading, transmitting, delivering, running, possessing or storing harmful code, malware or
                        illegal
                        content, and will ensure that none of the End Users engages in any such activity.
                    </li>
                    <li>Any Customer Data that conflicts with the provisions of this Agreement may be removed, disabled
                        and/or
                        destroyed by the Supplier at its sole discretion.
                    </li>
                    <li>Without derogating from any of the Customer's statutory obligations, the Customer undertakes
                        that it
                        will not, and will not allow any End User to:
                        <ol>
                            <li>Interfere with the proper functioning of the Service</li>
                            <li>Impose an unreasonable load on the Service or its infrastructure</li>
                            <li>Copy (except as expressly permitted herein), reproduce, translate, adapt, arrange or
                                otherwise
                                alter the Software or reproduce the results of any such activity
                            </li>
                            <li>Decompile, disassemble or otherwise reverse engineer the Software</li>
                            <li>Create or attempt to create any product or service that is substantially similar to, or
                                performs
                                the same or substantially similar functions as, or otherwise competes with the Service,
                                or
                                purports to be created, provided or approved by the Supplier or its licensors.
                            </li>
                        </ol>
                        The undertakings of the Customer set forth in this section shall also be deemed to have been
                        made by
                        anyone who uses, accesses, or attempts to use or access any part of the Service.
                    </li>
                </ol>
            </li>

            <li>
                <h2>Representative’s undertaking</h2>
                <ol>
                    <li>The Representative personally warrants to the Supplier that:
                        <ol>
                            <li>The Customer conforms to the description set forth in section 6.1</li>
                            <li>He/she has the authority to act on the Customer’s behalf</li>
                            <li>This Agreement is binding on the Customer.</li>
                        </ol>
                    </li>
                    <li>The Representative undertakes to the Supplier, and the latter agrees, that if the Customer does
                        not
                        conform to the description referred to in section 7.1.1, or if this Agreement proves to be void
                        due to
                        the Representative’s lack or excess of authority, the Representative shall, at the Supplier’s
                        option (to
                        be exercised by notice at the Supplier’s discretion), be deemed to have entered into the
                        Agreement on
                        his/her own behalf (i.e., as the Customer). For the avoidance of doubt, the Supplier’s exercise,
                        or it
                        not exercising, the aforesaid option shall not prejudice any other right or remedy available to
                        it under
                        the Agreement or the applicable law.
                    </li>
                </ol>
            </li>

            <li>
                <h2>User account</h2>
                <ol>
                    <li>The Customer shall be fully responsible for the activity that occurs under its User Account,
                        including
                        all acts performed through or by means of such User Account, and must notify the Supplier
                        immediately of
                        any breach of security relating to or unauthorised use of its User Account.
                    </li>
                    <li>It shall be the Customer's own responsibility to maintain the confidentiality of its usernames,
                        passwords and authentication tokens.
                    </li>
                    <li>The Supplier shall not be responsible for any loss, damage or other consequences that may result
                        from
                        any unauthorised use of the Customer's User Account, username, password or authentication token.
                    </li>
                    <li>The Supplier has no obligation to monitor or access any User Account, but may do so in cases
                        where such
                        action is reasonably justified (e.g., in order to prevent illegal or harmful activity, provide
                        customer
                        support, or perform its legal duties).
                    </li>
                    <li>The Supplier may, in its sole discretion, disable, close or restrict access to any User Account
                        that is
                        used to infringe on any Intellectual Property or any proprietary or personal right of any party.
                    </li>
                </ol>
            </li>

            <li id="rights_1">
                <h2>Rights</h2>
                <ol>
                    <li>All Service-related Intellectual Property shall belong to the Supplier. The Customer shall not
                        acquire
                        any right thereto or interest therein or otherwise in connection with the Service, except for
                        the
                        limited rights of use expressly set forth in this Agreement. All rights not expressly granted
                        herein
                        shall be deemed withheld.
                    </li>
                    <li>Nothing in this Agreement or anyone’s conduct hereunder shall be construed to create or provide
                        grounds
                        for the creation of any right of security or that of possession, ownership, or any other real
                        right (ius
                        in re) in or for the benefit of the Customer or any End User with respect to any item belonging
                        to or in
                        the possession of the Supplier, unless the Supplier has explicitly consented to the creation
                        thereof.
                    </li>
                </ol>
            </li>

            <li id="contributions_1">
                <h2>Contributions</h2>
                <ol>
                    <li>With respect to any product of intellectual activity, including any object of
                        Intellectual Property, that is submitted, contributed or otherwise made available for inclusion
                        in the
                        Software, Content or any other part of the Service, the Supplier shall be deemed to have been
                        granted a
                        non-exclusive, royalty-free, worldwide, perpetual, irrevocable, freely transferable and fully
                        sub-licensable licence to use, distribute, reproduce, modify, adapt, publish, translate,
                        transmit,
                        publicly perform, display and make available same (in whole or in part) and to incorporate it
                        into other
                        works and inventions in any form or medium now known or later developed. Each Customer and / or
                        End User
                        making such a contribution warrants to the Supplier that s/he is authorised to do so and that
                        neither
                        s/he nor any author of any item that may be embedded in the contribution will claim any
                        compensation or
                        reimbursement in connection therewith.
                    </li>
                    <li>The provisions of section 10 shall also apply, mutatis mutandis, to all photos and
                        other works of graphical nature (including their embedded information) as well as all measuring
                        results
                        that the Customer or any End User creates or produces by means of the Service or any part
                        thereof,
                        provided, however, that the activities authorised under the licence shall be confined to the
                        following:
                        the Supplier’s and its contractors’ and resellers’ enhancement, development and other
                        modification,
                        translation, adaptation, reproduction, distribution (including, without limitation, sale,
                        licensing,
                        renting and leasing), transmission, display, publication and making available of the Software,
                        Content
                        and other parts of the Service and related materials.
                    </li>
                </ol>
            </li>

            <li>
                <h2>Disclaimers</h2>
                <ol>
                    <li>Any warranty of the Supplier not expressly stated herein shall be deemed withheld. The Supplier
                        disclaims, to the fullest extent permitted under the applicable law, all statutory warranties
                        and course
                        of performance, course of dealing and usage related licensees and users expectations.
                    </li>
                    <li>The Supplier makes no representation or warranty:
                        <ol>
                            <li>That the Service will meet the Customers or End Users requirements or expectations.</li>
                            <li>That access to or the operation or use of the Service will be uninterrupted, secure or
                                error-free
                            </li>
                            <li>That any defects in the Service will be corrected</li>
                            <li>That the Service or any means by which the Service is accessed or used is free of
                                malware or
                                other harmful components or
                            </li>
                            <li>With respect to any third-party software, service, information, infrastructure,
                                resource, or any
                                other third-party item.
                            </li>

                        </ol>
                    </li>
                </ol>
            </li>

            <li>
                <h2>Limitation of liability</h2>
                <ol>
                    <li id="limitation_1">The Supplier shall not be liable for any loss, damage, expenses or other
                        harmful
                        consequences resulting
                        from
                        <ol>
                            <li>Anyone's use or inability to use the Service</li>
                            <li>The properties of the Service</li>
                            <li>The need to procure or the procurement of substitute goods or services or any other
                                substitute
                                benefit for the Service or any information, asset or other benefit received, owned,
                                controlled
                                or otherwise enjoyed through the Service
                            </li>
                            <li>Any message or other communication received or transaction entered into through or from
                                the
                                Service
                            </li>
                            <li>Unauthorised access to or interruption, alteration, loss, corruption or deletion of the
                                Customer's or any End User's transmissions or data.
                            </li>
                            <li>The statements or conduct of any person having access to the Service, or</li>
                            <li>Any other matter relating to the Service or any part thereof; REGARDLESS of whether such
                                are
                                suffered or incurred directly or indirectly or are immediate or consequential and
                                whether
                                arising in contract, tort or otherwise; PROVIDED, HOWEVER, that
                                <ol>
                                    <li>this section shall not prevent claims for the compensation of direct patrimonial
                                        loss
                                        suffered by the Customer due to the Supplier's intentional or grossly negligent
                                        breach
                                        of this Agreement or patrimonial loss resulting from the Supplier's causing
                                        personal
                                        injury to, or the death of, the Customer, AND that
                                    </li>
                                    <li>the total cumulative liability of the Supplier, hereunder and otherwise, shall
                                        in no
                                        event exceed the total amount of all fees and other charges (exclusive of value
                                        added
                                        and sales taxes) that the Customer disbursed to the Supplier for the Service
                                        during the
                                        6 months immediately preceding that month in which the event giving rise to
                                        liability
                                        occurred.
                                    </li>
                                </ol>
                            </li>
                        </ol>
                    </li>
                    <li>Neither Party shall be liable for breaching its obligations due to a circumstance it reasonably
                        could
                        not have foreseen and which is beyond its control, such as, e.g., a force of nature, an act of a
                        legislative or an executive authority, war, civil unrest, act of terror, strike, Internet
                        failure or any
                        other circumstance qualifying as force majeure under the applicable law -- to the extent that
                        the
                        respective circumstance prevented or hindered the Party's performance. For the avoidance of
                        doubt, the
                        provisions of this section:
                        <ol>
                            <li>Are not intended to derogate from or limit the application of any statutory limitation
                                or
                                exclusion of liability
                            </li>
                            <li>Shall not be construed to limit the amount of, or excuse the Customer from paying, any
                                fee or
                                other consideration owed hereunder.
                            </li>
                        </ol>
                    </li>
                </ol>
            </li>

            <li id="indemnification_1">
                <h2>Indemnification</h2><br/>
                The Customer shall defend, indemnify and hold harmless the Supplier, its officers, directors, employees,
                contractors, agents and representatives from and against all claims made by and all damages,
                liabilities,
                penalties, fines, costs and expenses payable to any third party which arise from the Customer’s or any
                End
                User’s:
                <ol>
                    <li>Breach of this Agreement</li>
                    <li>Use of the Service</li>
                    <li>Processing of Customer Data</li>
                    <li>Contributions to the Service or</li>
                    <li>Infringement of any Intellectual Property or any proprietary or personal right.</li>
                </ol>
            </li>

            <li id="data_protection_0">
                <h2>Data protection</h2>
                <ol>
                    <li>While it is not the purpose of this Agreement to charge the Supplier with the duty of processing
                        any
                        Personal Data or authorise any significant Personal Data processing activities, the Customer and
                        the
                        Representative nevertheless acknowledge and agree that certain Personal Data and other
                        information about
                        the Customer, the Representative as well as End Users (each of the Customer, the Representative
                        and any
                        End User hereinafter a “Data Subject”) may be collected and processed by the Supplier and/or the
                        persons
                        to whom it may outsource or subcontract the Service or any part thereof (in this article
                        14, the Supplier and each such person a “Data Processor”).
                    </li>
                    <li>The Customer and the Representative agree, and the Customer warrants that each End User agrees:
                        <ol>
                            <li id="data_protection_1">That a Data Processor may have collected and may further collect
                                information (including Personal Data) about the Data Subjects:
                                <ol>
                                    <li>During the negotiation, conclusion and modification of this Agreement (the
                                        information
                                        collected may include the data provided in the Agreement as well as any data
                                        furnished
                                        for the purposes of negotiating, concluding or amending the Agreement)
                                    </li>
                                    <li>When a Data Subject fills in forms via the Service, creates or modifies a user
                                        profile
                                        or enters or modifies other information associated with the User Account (the
                                        information thus provided)
                                    </li>
                                    <li id="data_protection_2">When a Data Subject visits the Data Processor's website
                                        (the Data
                                        Subject's IP address, location, entry and exit pages, referral sites and
                                        keywords,
                                        session time and duration, activities on the site, certain software and hardware
                                        attributes)
                                    </li>
                                    <li>When a Data Subject downloads, installs, updates or uninstalls the Software or
                                        accesses
                                        or uses the Service (the location, manner, means and duration of such activity
                                        as well
                                        as other information the Data Subject may provide)
                                    </li>
                                    <li>When otherwise knowingly made available to the Data Processor (the information
                                        the Data
                                        Subject provides)
                                    </li>
                                </ol>
                            </li>
                            <li>That when visiting a Data Processor’s website, “cookies” may be stored within the
                                visitor’s
                                device
                            </li>
                            <li>To the Data Processors’ processing of their Personal Data and such other information as
                                referenced in point 14.2.1 (collectively, “User Data”) for the purposes of:
                                <ol>
                                    <li>Providing the Service</li>
                                    <li>Improving or otherwise modifying the Service and notifying the Customer and
                                        other
                                        relevant Data Subjects thereof
                                    </li>
                                    <li>Customising the content and/or layout of the Data Processor’s website or the
                                        Service for
                                        the particular visitor or user
                                    </li>
                                    <li>Replying to the Data Subjects’ communications and contacting them.</li>
                                    <li>Performing the Data Processor’s obligations towards the Data Subject</li>
                                    <li>Exercising and enforcing the Data Processor’s rights</li>
                                    <li>User statistics and other Service-related analyses</li>
                                    <li>Improving and further developing Software, Service, Content, and delivery of
                                        such.
                                    </li>
                                </ol>
                            </li>
                            <li>That User Data may be processed in the country of their domicile as well as outside it,
                                including in any member state of the Organisation for Economic Co-operation and
                                Development
                                (OECD) and any country participating in the European Economic Area (EEA)
                            </li>
                            <li>that none of the Data Processors will disclose their Personal Data to any third party
                                besides
                                the members of its corporate group, except when, to the extent and to persons
                                <ol>
                                    <li>Expressly allowed by the Customer or the respective Data Subject</li>
                                    <li>Required by law, or</li>
                                    <li>Necessary in order to perform the Data Processor’s obligations hereunder or its
                                        statutory obligations or to exercise its legal rights or defend against claims
                                        or other
                                        process.
                                    </li>
                                </ol>
                            </li>
                        </ol>
                    </li>
                    <li>If a Data Subject opts in to a Data Processor’s mailing or similar programme, the Data Processor
                        may use
                        their Personal Data to send them information about products, services, promotions and events
                        that the
                        Data Processor believes may be of interest to them. Any subscription to any such programme may
                        be
                        cancelled at will by either Party.
                    </li>
                    <li>The Supplier reserves the right to send the Customer certain communications relating to the
                        Service,
                        such as (e.g.) service announcements and administrative messages, without offering the Customer
                        the
                        opportunity to opt out of receiving them.
                    </li>
                    <li>The Customer agrees that the Supplier may from time to time include the Customer’s name and logo
                        in its
                        customer listings, examples of Software/Content use cases, marketing materials, press releases
                        and
                        similar communications (all within reason).
                    </li>
                    <li>Upon the Customer's request, the Supplier will grant the Customer access to, or, at the
                        Supplier's
                        option, provide the Customer with a statement of, all Personal Data that the Supplier maintains
                        about
                        the Customer, unless such information is otherwise reasonably available to the Customer or the
                        Supplier
                        is legally prohibited from disclosing such records. If any such Personal Data prove to be
                        incorrect or
                        misleading, the Customer is entitled to have the same corrected, or, where the Customer is able
                        and
                        authorised to modify such data, correct these data itself.
                    </li>
                    <li>The Supplier asks that all requests, enquiries, complaints and other communications that a Data
                        Subject
                        may wish to address to the Supplier in connection with the processing of User Data be submitted
                        via the
                        Service or that such communications be sent to the email or postal address specified in section
                        1.1 of this Agreement under the term “Supplier” (or such other address or email address
                        as the Supplier may have provided to the Customer for this purpose).
                    </li>
                </ol>
            </li>

            <li>
                <h2>Modification</h2>
                <ol>
                    <li>The Customer acknowledges that, from time to time, circumstances may arise which make it
                        necessary or
                        desirable to modify certain provisions of this Agreement. Such circumstances include:
                        <ol>
                            <li>The Supplier’s launch of a new service or a modification to the Service</li>
                            <li>A significant change in the Supplier’s legal environment</li>
                            <li>An order or a judgment being entered against or in favour of the Supplier</li>
                            <li>A significant corporate event, such as, e.g., the Supplier’s merger or acquisition or
                                its
                                reorganisation into a different type of entity
                            </li>
                            <li>The Supplier’s transfer of the enterprise or a part of the enterprise to which this
                                Agreement
                                pertains
                            </li>
                            <li>The ambiguity, invalidity, voidability or unenforceability of a provision herein</li>
                            <li>Any other event whose occurrence in the Supplier’s reasonable judgment necessitates an
                                amendment
                                hereto.
                            </li>
                        </ol>
                    </li>
                    <li>The Customer agrees that:
                        <ol>
                            <li id="modification_0">Upon the occurrence of any of the circumstances referenced in the
                                preceding
                                section, the Supplier shall be entitled to make such changes to the Agreement as it
                                reasonably
                                deems appropriate
                            </li>
                            <li>The Supplier's ability to foresee an event or prevent it from happening shall not
                                prejudice its
                                right to amend the Agreement under point 15.2.1 of this section
                            </li>
                            <li>The Supplier may amend the Agreement by notifying the Customer thereof or by posting a
                                revised
                                version of the Agreement on a website and sending the Customer a link thereto
                            </li>
                            <li>If the revised version of the Agreement includes an amendment that reduces the
                                Customer's rights
                                or increases its responsibilities, the Supplier will give the Customer reasonable prior
                                notice
                                of such new version's entry into force.
                            </li>
                        </ol>
                    </li>
                    <li>Notwithstanding anything herein to the contrary, the Supplier reserves the right to modify the
                        Service
                        at any time for any reason, with or without notice. Unless otherwise expressly agreed, the use
                        of any
                        new features, versions, releases, updates or other modifications that the Supplier may make
                        available in
                        connection with the Service shall be subject to the Agreement. The Customer’s continued use of
                        the
                        Service after any such modification shall constitute the Customer’s consent to the respective
                        modification(s).
                    </li>
                    <li>If the Customer does not agree with the Supplier’s changes (whether to the Agreement or the
                        Service),
                        its sole and exclusive remedy shall be to cancel the Agreement and terminate its use of the
                        Service.
                    </li>
                </ol>
            </li>

            <li>
                <h2>Suspension</h2>
                <ol>
                    <li>The Supplier may discontinue providing the Service or any part thereof upon thirty (30) days
                        notice and
                        may, without notice, suspend or restrict access to the Service for any Customer whose payment
                        for the
                        Service remains overdue for more than five (5) business days or whose use of the Service
                        conflicts with
                        the provisions of this agreement.
                    </li>
                    <li>The Supplier may suspend performance under the Agreement in whole or in part with immediate
                        effect if it
                        is required by law or a judicial or an administrative authority to refrain from performing its
                        obligations hereunder.
                    </li>
                    <li>The Supplier may discontinue providing the Service to any Customer or End User using Software,
                        Content,
                        or parts thereof for any activities that are against local or international laws.
                    </li>
                </ol>
            </li>

            <li>
                <h2>Termination</h2>
                <ol>
                    <li>This Agreement can only be terminated by closing the Customer’s User Account.</li>
                    <li>In order for the Customer to close its User Account, the Customer must log on to the Service and
                        follow
                        the instructions provided there (or, should such be unavailable, notify the Supplier thereof and
                        follow
                        the latter’s instructions).
                    </li>
                    <li>Either Party may close the Customer’s User Account and shall by so doing be deemed to have
                        cancelled
                        this Agreement, provided that:
                        <ol>
                            <li>If the User Account is closed by the Supplier, the Customer must be given at least a
                                month’s
                                notice thereof (unless section 17.4 applies or the User Account is closed at the
                                Customer’s request); and
                            </li>
                            <li>Where the User Account is closed by a Party entitled to withdraw from the Agreement,
                                such Party
                                has not notified the other that its closure of the User Account is to be construed as a
                                withdrawal (which notice must be served prior to or concurrently with closing the User
                                Account
                                and shall result in the Agreement being deemed to have been terminated by withdrawal).
                            </li>
                        </ol>
                    </li>
                    <li id="termination_0">Should either Party commit a material breach of this Agreement, the other
                        Party may
                        terminate the Agreement forthwith. Each of the following shall constitute a material breach of
                        Agreement
                        by the respective Party:
                        <ol>
                            <li>A Party having breached any of its substantive obligations hereunder fails to
                                discontinue or
                                remedy such breach within 30 days after notice from the other Party specifying the
                                breach and
                                requiring it to be discontinued or remedied
                            </li>
                            <li>Any act, omission, event or circumstance considered under the applicable law to be a
                                Party’s
                                material breach of this Agreement.
                            </li>
                        </ol>
                    </li>
                    <li>Any Customer having entered into this Agreement as a “consumer” (as determined under the
                        applicable law,
                        but generally — a natural person not transacting within the scope of his/her business or
                        professional
                        activities) may withdraw from the Agreement within 14 days of having become a party hereto.
                        Notwithstanding anything herein to the contrary, any consumer who withdraws from the Agreement
                        pursuant
                        to the preceding sentence is entitled to a full refund of all fees and other charges s/he has
                        paid to
                        the Supplier hereunder, provided, however, that s/he also returns to the Supplier, or reasonably
                        compensates the Supplier for the value of, everything s/he has received hereunder.
                    </li>
                    <li>The Customer understands and agrees that upon any termination of this Agreement:
                        <ol>
                            <li>All rights that the Customer has been granted hereunder will terminate</li>
                            <li>The Customer must cease all activities authorised by the Agreement</li>
                            <li>All amounts owed to the Supplier hereunder become due</li>
                            <li>All Customer Data and other information associated with the Customer's User Account may
                                be
                                deleted or become unavailable to the Customer
                            </li>
                            <li>The Customer will receive no refund, exchange or other compensation for any unused time
                                or
                                credit on a subscription, for any licence or subscription fee, any data associated with
                                any User
                                Account, or for anything else
                            </li>
                            <li>all its Software-related obligations hereunder shall survive until the Customer has
                                fully
                                removed all Software from its devices, systems and storage media.
                            </li>
                        </ol>
                    </li>
                    <li>Sections 9, 10, 12.1, 13,
                        14.2.1.3 will remain valid indefinitely after the termination of this Agreement by either
                        Party for any reason.
                    </li>
                </ol>
            </li>

            <li>
                <h2>Notice</h2>
                <ol>
                    <li>The Customer agrees that the Supplier may serve notice on the Customer by posting it on the
                        Service or
                        by sending it to the email address which the Customer has associated with its User Account. All
                        notices
                        to the Supplier must be sent to the email address (or postal address) specified in section
                        1.1 of this Agreement under the term “Supplier” (or such other address or email address
                        as the Supplier may have provided to the Customer for this purpose).
                    </li>
                    <li>All notices, requests, enquiries, complaints and other communications that the Customer (or any
                        Data
                        Subject) wishes to address to the Supplier should be sent to the email or postal address
                        specified in
                        section 1.1 under the term "Supplier" (or such other address or email address as the
                        Supplier may have provided for this purpose).
                    </li>
                </ol>
            </li>

            <li>
                <h2>Law and jurisdiction</h2><br/>
                This Agreement shall be governed by the laws of the Republic of Poland. The United Nations Convention on
                Contracts for the International Sale of Goods shall not apply to this Agreement. All disputes arising
                from or
                otherwise concerning this Agreement (including disputes concerning the formation or validity hereof)
                shall be
                resolved by the competent courts in the Republic of Poland.
            </li>

            <li>
                <h2>Miscellaneous</h2><br/>
                If any provision of this Agreement violates any mandatory rule of the applicable law and proves to be
                void as a
                result thereof, such provision shall, for those specific circumstances and only in that particular
                respect in
                which it is void, be deemed to have been amended so as to comply with the law. Any such amendment shall
                be
                confined to the minimum necessary to make the provision valid and shall retain as much of its original
                ambit and
                meaning as possible.
            </li>
            <li>
                <h2>Third Party Notices</h2><br/>
                This Application makes use of open-source libraries. We hereby acknowledge and attribute the respective
                copyright
                holders of these libraries in accordance with their licenses:
                <ol>
                    <li>OpenCV v4.7<br/>
                        License Type: Apache License 2.0 <br/>
                        Copyright (C) 2015-2023, OpenCV Foundation, all rights reserved. <br/>
                        <InnerLink href="https://www.apache.org/licenses/LICENSE-2.0">https://www.apache.org/licenses/LICENSE-2.0</InnerLink>
                    </li>
                    <li>
                        Apache POI 5.2.2 <br/>
                        License Type: Apache License 2.0 <br/>
                        Copyright © 2002-2023, The Apache Software Foundation, all rights reserved. <br/>
                        <InnerLink href="https://www.apache.org/licenses/LICENSE-2.0">https://www.apache.org/licenses/LICENSE-2.0</InnerLink>
                    </li>
                </ol>
                Unless required by applicable law or agreed to in writing, software distributed under the License is
                distributed
                on an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied. See the
                License
                for
                the specific language governing permissions and limitations under the License.
            </li>
        </ol>
    </Box>
}

export {
    TermsPage
}