import {
    AppBar,
    Box,
    Button, Collapse,
    IconButton,
    List,
    ListItemButton, ListItemText,
    Menu,
    MenuItem,
    Stack,
    Toolbar,
    useScrollTrigger, useTheme
} from "@mui/material";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import React, {useState} from "react";
import logo from "../img/logo.svg";
import PropTypes from "prop-types";
import {GetTheAppWhiteButton} from "./buttons/GetTheAppWhiteButton";
import MenuIcon from '@mui/icons-material/Menu';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import {Link, useNavigate} from "react-router-dom";


function ElevationScroll(props) {
    const {children, window} = props;
    // Note that you normally won't need to set the window ref as useScrollTrigger
    // will default to window.
    // This is only being set here because the demo is in an iframe.
    const trigger = useScrollTrigger({
        disableHysteresis: true,
        threshold: 0,
        target: window ? window() : undefined,
    });

    return React.cloneElement(children, {
        elevation: trigger ? 4 : 0,
    });
}

ElevationScroll.propTypes = {
    children: PropTypes.element.isRequired,
    /**
     * Injected by the documentation to work in an iframe.
     * You won't need it on your project.
     */
    window: PropTypes.func,
};

function TopMenu(props) {
    const menuButtonsStyle = {
        fontSize: "14px",
        fontWeight: 400,
        textTransform: "none",
        color: "#5F6368"
    };

    const MENU_PRODUCT = "product";
    const MENU_COMPANY = "company";

    const [anchorEl, setAnchorEl] = useState(null);
    const theme = useTheme();
    const [isProductExpanded, setIsProductExpanded] = useState(false);
    const [isCompanyExpanded, setIsCompanyExpanded] = useState(false);
    const [isListMenuExpanded, setIsListMenuExpanded] = useState(false);


    const handleClick = (event, menuName) => {
        setAnchorEl({anchor: event.currentTarget, menuName: menuName});
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const navigate = useNavigate();

    const menuItemClick = (link) => () => {
        handleClose();
        navigate(link);
    }


    return <>
        <ElevationScroll {...props}>
            <AppBar position="sticky" id="appBar" sx={{
                backgroundColor: "white",
                paddingLeft: {
                    lg: "80px",
                    xs: "20px"
                },
                paddingRight: {
                    lg: "80px",
                    xs: "20px"
                },
                paddingTop: {
                    lg: "18px",
                    xs: "8px"
                },
                paddingBottom: {
                    lg: "18px",
                    xs: "8px"
                },
                maxWidth: "1280px",
                marginLeft: "auto",
                marginRight: "auto"

            }}>
                <Stack direction="column">
                    <Toolbar sx={{
                        paddingLeft: "0px !important",
                        paddingRight: "0px !important",

                    }}>
                        <Box sx={{
                            display: {
                                xs: "none",
                                lg: "block"
                            }
                        }}>
                            <Link to="/#about"><img src={logo} alt="Logsizer"/></Link>
                        </Box>
                        <IconButton onClick={() => setIsListMenuExpanded(!isListMenuExpanded)}
                                    sx={{
                                        display: {
                                            xs: "block",
                                            lg: "none"
                                        }
                                    }}>
                            <MenuIcon/>
                        </IconButton>
                        <Box sx={{
                            flexGrow: 1,
                            textAlign: "center",
                            display: {
                                xs: "block",
                                lg: "none"
                            }
                        }}>
                            <Link to="/#about"><img src={logo} alt="Logsizer"/></Link>
                        </Box>
                        <Box sx={{
                            flexGrow: 1,
                            textAlign: "center",
                            display: {
                                xs: "none",
                                lg: "block"
                            }
                        }}>
                            <Button variant="text"
                                    sx={menuButtonsStyle}
                                    endIcon={<KeyboardArrowDownIcon/>}
                                    onClick={event => handleClick(event, MENU_PRODUCT)}
                                    id="productMenuButton"
                            >Product</Button>
                            <Menu
                                aria-labelledby="productMenuButton"
                                anchorEl={anchorEl?.anchor}
                                open={anchorEl?.menuName === MENU_PRODUCT}
                                onClose={handleClose}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'left',
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'left',
                                }}
                                sx={{
                                    display: {
                                        xs: "none",
                                        lg: "block"
                                    }
                                }}
                            >
                                <MenuItem onClick={menuItemClick("/#howItWorks")}>How it works</MenuItem>
                                <MenuItem onClick={menuItemClick("/#benefits")}>Benefits</MenuItem>
                                <MenuItem onClick={menuItemClick("/#features")}>Features</MenuItem>
                            </Menu>
                            <Button variant="text"
                                    sx={menuButtonsStyle}
                                    endIcon={<KeyboardArrowDownIcon/>}
                                    onClick={event => handleClick(event, MENU_COMPANY)}
                                    id="companyMenuButton"
                            >Company</Button>
                            <Menu
                                aria-labelledby="companyMenuButton"
                                anchorEl={anchorEl?.anchor}
                                open={anchorEl?.menuName === MENU_COMPANY}
                                onClose={handleClose}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'left',
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'left',
                                }}
                                sx={{
                                    display: {
                                        xs: "none",
                                        lg: "block"
                                    }
                                }}
                            >
                                <MenuItem onClick={menuItemClick("/#roadmap")}>Plans for the future</MenuItem>
                                <MenuItem onClick={menuItemClick("/#team")}>Team</MenuItem>
                            </Menu>

                            <Button variant="text" onClick={menuItemClick("/#contacts")} sx={menuButtonsStyle}>Contacts</Button>

                        </Box>
                        <GetTheAppWhiteButton sx={{
                            display: {
                                xs: "none",
                                lg: "block"
                            }
                        }}/>
                    </Toolbar>
                    <Collapse in={isListMenuExpanded} unmountOnExit>
                        <List sx={{
                            display: {
                                xs: "block",
                                lg: "none"
                            },
                            color: theme.palette.text.primary
                        }}>
                            <ListItemButton onClick={() => setIsProductExpanded(!isProductExpanded)}>
                                <ListItemText primary="Product"/>
                                {isProductExpanded ? <ExpandLess/> : <ExpandMore/>}
                            </ListItemButton>
                            <Collapse in={isProductExpanded} unmountOnExit>
                                <List component="div" disablePadding>
                                    <ListItemButton sx={{pl: 4}}>
                                        <ListItemText primary="How it works" onClick={menuItemClick("/#howItWorks")}/>
                                    </ListItemButton>
                                    <ListItemButton sx={{pl: 4}}>
                                        <ListItemText primary="Benefits" onClick={menuItemClick("/#benefits")}/>
                                    </ListItemButton>
                                    <ListItemButton sx={{pl: 4}}>
                                        <ListItemText primary="Features" onClick={menuItemClick("/#features")}/>
                                    </ListItemButton>
                                </List>
                            </Collapse>

                            <ListItemButton onClick={() => setIsCompanyExpanded(!isCompanyExpanded)}>
                                <ListItemText primary="Company"/>
                                {isCompanyExpanded ? <ExpandLess/> : <ExpandMore/>}
                            </ListItemButton>
                            <Collapse in={isCompanyExpanded} unmountOnExit>
                                <List component="div" disablePadding>
                                    <ListItemButton sx={{pl: 4}}>
                                        <ListItemText primary="Plans for the future" onClick={menuItemClick("/#roadmap")}/>
                                    </ListItemButton>
                                    <ListItemButton sx={{pl: 4}}>
                                        <ListItemText primary="Team" onClick={menuItemClick("/#team")}/>
                                    </ListItemButton>
                                </List>
                            </Collapse>
                            <ListItemButton>
                                <ListItemText primary="Contacts" onClick={menuItemClick("/#contacts")}/>
                            </ListItemButton>
                        </List>
                    </Collapse>
                </Stack>
            </AppBar>
        </ElevationScroll>
    </>

}

export {TopMenu};