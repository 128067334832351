import {BaseSlide} from "./BaseSlide";
import {Chart} from "./Chart";
import {Typography} from "@mui/material";
import image from "./img/getReport.png";

function GetReportSlide() {
    return <BaseSlide TitleIcon={Chart} title="Generate" caption="Get the results in an Exel data table"
                      image={image} imgStyle={{maxWidth: "295px"}}
    >
        <Typography variant="body2">Report includes logs’ diameter, count and volume, the photo of the log stack and the
            geolocation point where the measurement was taken.</Typography>
    </BaseSlide>
}

export {GetReportSlide};