import {Box, Button, Stack, Typography, useTheme} from "@mui/material";
import gpIcon from "./img/googlePlayLogoLight.svg";

function GetTheAppButton() {
    const theme = useTheme();

    return <Button variant="text" className="getAppSolid" href="https://play.google.com/store/apps/details?id=com.fosize.android" sx={{
        color: "white",
        background: `linear-gradient(to bottom right, ${theme.palette.gradients.greenStart}, ${theme.palette.gradients.greenEnd})`,
        height: "50px",
        paddingLeft: "43px",
        paddingRight: "43px",
        borderRadius: "10px",
        /*border: "none",*/
        border: "1px solid transparent",
        "&:hover": {
            background: `linear-gradient(to right, ${theme.palette.gradients.orange}, ${theme.palette.gradients.orangeGreen})`,
            border: "1px solid black",
            color: "black"
        }
    }}>
        <Stack direction="row"
               justifyContent="center"
               alignItems="center"
        >
            <Box className="googleHower" sx={{
                background: `url(${gpIcon}) center center no-repeat`,
                width: "21px",
                height: "20px",
            }}>

            </Box>

            <Typography sx={{
                textTransform: "none",
                paddingLeft: "10px",
                fontWeight: 300,
                fontSize: "16px"
            }}>Get the app</Typography>
        </Stack>
    </Button>
}

export {GetTheAppButton};