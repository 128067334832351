import {Box, Typography, useTheme} from "@mui/material";

function Header3({children, sx}) {
    const theme = useTheme();

    return <Typography variant="h3" sx={{
        ...sx,
        fontSize: {xs: "24px", lg: "36px"},
        fontWeight: {xs: 500, lg: 300},
    }}>
        <Box sx={{
            display: "inline-block",
            background: `linear-gradient(to bottom right, ${theme.palette.gradients.greenStart}, ${theme.palette.gradients.greenMiddle})`,
            backgroundClip: "text",
            color: "transparent",
            paddingLeft: {xs: "8px", lg: "0px"},
            paddingRight: {xs: "8px", lg: "0px"},
        }}>
            {children}
        </Box>
    </Typography>
}

export {Header3}