import {Box, Grid, Typography} from "@mui/material";
import {Header2} from "../Header2";
import {Header3} from "../Header3";
import {Feature} from "./Feature";
import getReportIcon from "./img/getReports.svg";
import folderIcon from "./img/folder.svg";
import cropIcon from "./img/crop.svg";
import panoramaIcon from "./img/panorama.svg";

function Features() {
    return <Box sx={{paddingTop: "90px", paddingBottom: "98px"}}>
        <Header2 id="features" sx={{marginBottom: "58px"}}>Features</Header2>
        <Header3>From photo to measurements to report —<br/>get everything in just under 5 minutes</Header3>
        <Grid container sx={{
            paddingLeft: {lg: "204px", xs: "20px"},
            paddingRight: {lg: "204px", xs: "20px"},
            paddingTop: "43px",
            rowGap: "50px",

        }}>
            <Grid item lg={6} md={6}>
                <Feature header="Get reports in the units you need" icon={getReportIcon}>Fosize supports
                    metric <Typography
                        variant="body2Accent">m</Typography>, <Typography variant="body2Accent">cm</Typography>,
                    imperial units <Typography variant="body2Accent">ft</Typography>, in and board foot
                    measurements <Typography variant="body2Accent">FBM</Typography>. Easily
                    switch between them in the settings.
                </Feature>
            </Grid>
            <Grid item lg={6} md={6} sx={{
                paddingLeft: {lg: "26px", xs: "0px"}
            }}>
                <Feature header="Group and combine Measurements" icon={folderIcon}>
                    Organize your measurements into folders and group them by date, geographic location, work site, or
                    client name.
                </Feature>
            </Grid>
            <Grid item lg={6} md={6}>
                <Feature header="Crop out unnecessary logs" icon={cropIcon}>When there are multiple log stacks in the
                    photo, this feature allows you to select only the stack or part of a stack you want to
                    measure.</Feature>
            </Grid>
            <Grid item lg={6} md={6} sx={{
                paddingLeft: {lg: "26px", xs: "0px"}
            }}>
                <Feature header="Manage even the largest stacks" icon={panoramaIcon}>If a stack is too large to fit in
                    one photo, use Panorama Mode. This mode allows you to take multiple photos and combines them into
                    one complete image.</Feature>
            </Grid>

        </Grid>
    </Box>
}

export {Features};