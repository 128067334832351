import {BaseSlide} from "./BaseSlide";
import {Send} from "./Send";
import image from "./img/share.png";
import {Typography} from "@mui/material";
import {InnerLink} from "../InnerLink";

function ShareSlide() {
    return <BaseSlide TitleIcon={Send} title="Send" caption="Share the report" image={image}>
        <Typography variant="body2" sx={{marginBottom: "12px"}}>You can share it instantly via email, messengers, social
            networks or cloud services.</Typography>
        <Typography variant="body2">The measurement data can also be custom-integrated with your current ERP
            system - please <InnerLink  sx={{fontWeight: "bold"}} href="/#contacts">contact us</InnerLink> for more information.</Typography>
    </BaseSlide>
}

export {ShareSlide};