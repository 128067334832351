import image from "./img/safity.png";
import {Typography, useTheme} from "@mui/material";
import {BenefitSlide, bodyTextStyle, getBodyAccentTextStyle} from "./BenefitSlide";

function SafitySlide() {
    const theme = useTheme();
    return <BenefitSlide image={image} title="Create a safer workplace"
                         altText="Measuring logs traditionally often needs to be done in harsh weather conditions, which can result in accidents.">

        <Typography sx={bodyTextStyle}>LogSizerPro makes logging safer by <Typography as="span"
                                                                                      sx={getBodyAccentTextStyle(theme)}>reducing
            physical interaction</Typography> with log stacks and the amount of time spent in the heat, rain, or
            snow.</Typography>
    </BenefitSlide>
}

export {SafitySlide};