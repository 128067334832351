import {Box, Card, CardActionArea, CardContent, Stack, Typography, useTheme} from "@mui/material";

function NewTab({TitleIcon, title, children, isActive, onClick}) {
    const theme = useTheme();
    const activeHeaderStyle = {
        background: `linear-gradient(to right, ${theme.palette.gradients.greenStart}, ${theme.palette.gradients.greenMiddle})`,
        backgroundClip: "text",
        color: "transparent",
    };

    const activeBorderStyle = {
        background: `linear-gradient(to bottom right, ${theme.palette.gradients.greenStart}, ${theme.palette.gradients.greenEnd})`,
    };

    return <Card
        sx={{
            boxShadow: "none",
            maxWidth: "236px",
            width: "236px",
            borderRadius: "16px",
            height: "150px",
            padding: "1px",
            ...(isActive ? activeBorderStyle : {}),
        }}
        onClick={onClick}
    >
        <CardActionArea  sx={{
            "& .MuiCardActionArea-focusHighlight": {
                background: "transparent",
            },
            height: "100%",
        }}>
            <CardContent sx={{
                backgroundColor: isActive ? theme.palette.secondary.light : "white",
                height: "100%",
                borderRadius: "15px",
                padding: "22px"
            }}>
                <Stack direction="row" alignItems="center" height={24} spacing={1}>
                    <TitleIcon className={(isActive ? "activeTabTitleIcon" : "normalTabTitleIcon")}/>
                    <Box className={isActive ? "" : "tabTitleContainer"}>
                        <Typography variant="h5" className={isActive ? "" : "tabTitle"}
                                    sx={isActive ? activeHeaderStyle : {color: "black"}}>
                            {title}
                        </Typography>
                    </Box>
                </Stack>
                <Typography variant="body2" sx={{
                    paddingTop: "12px",
                    color: isActive ? "black" : "#606060"
                }}>
                    {children}
                </Typography>
            </CardContent>
        </CardActionArea>
    </Card>
}

export {NewTab};